import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Input } from 'react-daisyui';
import type { AlphabetFilterProps } from '~/components/common/AlphabetFilter';
import { AlphabetFilter } from '~/components/common/AlphabetFilter';
import { InputGroup } from '~/components/common/InputGroup';
import { useDebounce } from '~/hooks/debounce';
import { cn } from '~/utils/common';

export interface FilterSearchProps {
  startsWithValue: string;
  includesValue: string;
  onStartsWithChange: (value: string) => any;
  onIncludesChange: (value: string) => any;
  alphabetFilterProps?: Partial<AlphabetFilterProps>;
  renderSearch?: (component: React.ReactNode) => React.ReactNode;
  stick?: boolean;
  showAlphabet?: boolean;
}

export function FilterSearch({
  startsWithValue,
  includesValue,
  onStartsWithChange,
  onIncludesChange,
  alphabetFilterProps,
  renderSearch,
  stick = true,
  showAlphabet = true,
}: FilterSearchProps) {
  const [inputValue, setInputValue] = useState('');
  const inputValueDb = useDebounce(inputValue, 300);
  const [stuck, setStuck] = useState(false);
  const [originalDivPosition, setOriginalDivPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Some of the lists are pretty heavy (e.g. outcrop list) and typing gets laggy.
  // Let's keep the state local and debounce the value before flushing to the search
  useEffect(() => {
    onIncludesChange(inputValueDb);
  }, [onIncludesChange, inputValueDb]);

  useLayoutEffect(() => {
    if (!stick) return;

    function handleScroll() {
      if (containerRef.current) {
        if (window.pageYOffset > originalDivPosition) {
          setStuck(true);
        } else {
          setStuck(false);
        }
      }
    }

    if (containerRef?.current) {
      setOriginalDivPosition(containerRef.current.getBoundingClientRect().top);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [originalDivPosition, stick]);

  const searchBox = (
    <div className="form-control">
      <InputGroup>
        <InputGroup.Addon>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </InputGroup.Addon>
        <Input
          bordered
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          className="w-full join-item"
          placeholder="Search"
        />
      </InputGroup>
    </div>
  );

  return (
    <div
      ref={containerRef}
      className={cn('space-y-1', {
        'fixed top-0 left-0 z-10 w-full bg-white shadow-md p-4': stuck,
        'mb-4': !stuck && !renderSearch,
      })}
    >
      {showAlphabet && (
        <AlphabetFilter
          value={startsWithValue}
          onFilterChange={onStartsWithChange}
          {...alphabetFilterProps}
        />
      )}
      {renderSearch ? (
        renderSearch(searchBox)
      ) : (
        <div className="lg:grid lg:grid-cols-12">
          <div className="lg:col-span-4 lg:col-start-5">{searchBox}</div>
        </div>
      )}
    </div>
  );
}
