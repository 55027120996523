import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { Button, Join } from 'react-daisyui';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { FormikField } from '~/components/common/FormikField';
import { FormikReactSelectField } from '~/components/common/FormikField/FormikReactSelectField';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import type { MiniModelFormValues } from '~/utils/modules/urlBasedSO';

const equipment = [
  'iPhone 12 Pro',
  'iPhone 13 Pro',
  'iPhone 14 Pro',
  'iPhone 15 Pro',
  'iPhone 16 Pro',
  'iPad Pro 2gen',
  'iPad Pro 3gen',
  'iPad Pro 4gen',
  'iPad Pro 5gen',
  'iPad Pro 6gen',
  'iPad Pro 7gen',
  'other',
];

const urlHelpText = (
  <div>
    <p>Sketchfab embed src:</p>
    <p className="text-xs text-muted">
      https://sketchfab.com/models/&lt;model-id&gt;/embed
    </p>
  </div>
);

export function MiniModelFormFields() {
  const { setValues } = useFormikContext<MiniModelFormValues>();

  function handleDetailsLoaded(details: ModelDetails) {
    setValues(values => ({
      ...values,
      name: details.name,
      url: details.embedUrl,
      sketchfabName: details.name,
      longitude: String(details.longitude ?? ''),
      latitude: String(details.latitude ?? ''),
    }));
  }

  return (
    <div className="space-y-2">
      <LoadFromSketchfabApi onLoadSuccess={handleDetailsLoaded} />

      <Field name="name" label="Name" component={FormikField} required />
      <Field name="description" label="Description" component={FormikField} />
      <Field
        name="url"
        label="Embed URL"
        component={FormikField}
        placeholder="https://sketchfab.com/models/<model-id>/embed"
        helpText={urlHelpText}
        required
      />
      <div className="grid lg:grid-cols-2 gap-6">
        <Field
          name="collectedBy"
          label="Collected by"
          component={FormikField}
          required
        />
        <Field
          name="yearCollected"
          label="Year collected"
          component={FormikField}
          placeholder={new Date().getFullYear()}
          required
        />
      </div>
      <Field
        name="equipment"
        label="Equipment"
        component={FormikField}
        type={FormikReactSelectField}
        options={equipment.map(value => ({ value, label: value }))}
        required
      />
      <Field
        name="scaniverseName"
        label="Scaniverse name"
        component={FormikField}
        required
      />
      <Field
        name="sketchfabName"
        label="Sketchfab name"
        component={FormikField}
      />
      <Field
        name="comments"
        label="Comments"
        component={FormikField}
        type="textarea"
      />

      <div className="grid lg:grid-cols-2 gap-6">
        <Field name="longitude" label="Longitude" component={FormikField} />
        <Field name="latitude" label="Latitude" component={FormikField} />
      </div>

      <div className="pt-2 space-y-2">
        <Field
          name="readyForApproval"
          label="Ready for approval"
          component={FormikField}
          type="checkbox"
        />

        <Field
          name="approved"
          label="Approved"
          component={FormikField}
          type="checkbox"
        />
      </div>
    </div>
  );
}

const schema = z
  .object({
    uid: z.string(),
    name: z.string(),
    description: z.string(),
    embedUrl: z.string().url(),
  })
  .transform(parsed => {
    const regex = /-?\d+(\.\d+)?,\s?-?\d+(\.\d+)?/;
    const matches = parsed.description.match(regex);
    const coordinates = matches?.[0]?.split(',').map(s => s.trim());

    console.log('Matches:', matches);
    console.log('Coordinates:', coordinates);

    const latRaw = coordinates?.at(0);
    const lngRaw = coordinates?.at(1);
    const latitude = typeof latRaw === 'string' ? parseFloat(latRaw) : null;
    const longitude = typeof lngRaw === 'string' ? parseFloat(lngRaw) : null;

    return { ...parsed, latitude, longitude };
  });

type ModelDetails = z.infer<typeof schema>;

async function loadDetails(uid: string) {
  const url = `https://api.sketchfab.com/v3/models/${uid}`;

  const result = await fetch(url)
    .then(res => res.json())
    .catch(() => {
      console.log('Error fetching from Sketchfab');
      return null;
    });

  try {
    return schema.parse(result);
  } catch (err) {
    console.log("Couldn't parse response body?");
    return null;
  }
}

function LoadFromSketchfabApi({
  onLoadSuccess,
}: {
  onLoadSuccess: (details: ModelDetails) => void;
}) {
  const [modelUrl, setModelUrl] = useState('');

  const [loading, setLoading] = useState(false);

  async function loadModel() {
    const parts = modelUrl.split('-');
    const modelUid = parts.at(parts.length - 1);
    if (!modelUid?.length) return;

    console.log('Loading model with URL:', modelUrl);

    setLoading(true);
    const details = await loadDetails(modelUid);
    console.log('Loaded details:', details);
    setLoading(false);

    if (details) {
      toast.success(`Model details successfully loaded for ${details.name}.`);
      onLoadSuccess(details);
    } else {
      toast.error("Model details couldn't be loaded automatically.");
    }
  }

  const handleKey: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.code === 'Enter') {
      event.preventDefault();
      // event.stopPropagation();
      loadModel();
    }
  };

  return (
    <div className="bg-slate-100 p-4 w-full">
      <FormLabel name="modelUrl" label="Fetch details from Sketchfab" />
      <Join className="w-full">
        <input
          id="modelUrl"
          type="text"
          value={modelUrl}
          onChange={event => setModelUrl(event.target.value)}
          disabled={loading}
          className="input input-bordered join-item w-full"
          placeholder="https://sketchfab.com/3d-models/<model-name>-<random-id>"
          onKeyDown={handleKey}
        />

        <Button
          type="button"
          onClick={loadModel}
          disabled={loading}
          className="join-item"
          color="info"
        >
          Load
        </Button>
      </Join>
    </div>
  );
}
