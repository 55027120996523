import type { ReactNode } from 'react';
import { Button } from 'react-daisyui';
import { Modal } from '~/components/common/Modal';
import { PageLoader } from '~/components/page/PageLoader';
import { useModalState } from '~/hooks/modal';

export function DataSearchHelpModal({
  children,
}: {
  children: (showModal: () => void) => ReactNode;
}) {
  const { show, showModal, hideModal } = useModalState();

  return (
    <>
      {children(showModal)}
      <Modal size="lg" open={show} onHide={hideModal} closeable>
        <Modal.Body>
          <PageLoader pageName="data-how-to" showTitle={false} />
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" color="ghost" size="sm" onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
