import { gql } from '@apollo/client';
import * as R from 'ramda';
import React, { useCallback, useContext, useState } from 'react';
import invariant from 'tiny-invariant';
import * as fragments from '~/apollo/fragments';
import type {
  AuthorityPartsFragment,
  AuthorityUserPartsFragment,
  CurrentAuthorityQuery,
  Role,
} from '~/apollo/generated/schema';
import { useImperativeQuery } from '~/hooks/apollo';

export const CURRENT_AUTHORITY = gql`
  query CurrentAuthority {
    currentAuthority {
      ...authorityParts
    }
  }

  ${fragments.authorityParts}
  ${fragments.authorityUserParts}
  ${fragments.companyParts}
`;

export type Authority = AuthorityPartsFragment;

export type AuthContextValue = {
  authority: Authority | null;
  setAuthority: (authority: Authority | null) => void;
  setUser: (
    user: Omit<AuthorityUserPartsFragment, '__typename' | 'isSocialLogin'>,
  ) => Authority;
  isAuthenticated: boolean;
  hasAnyRole: (roles: Role[]) => boolean;
  logout: () => void;
  reloadAuthority: () => Promise<Authority | null>;
};

export const AuthContext = React.createContext<AuthContextValue | null>(null);

type Props = {
  children: React.ReactNode;
  initialAuthority: Authority | null;
};

export const AuthContextProvider: React.FC<Props> = ({
  children,
  initialAuthority,
}) => {
  const [authority, setAuthority] = useState<Authority | null>(
    initialAuthority,
  );
  const [loadCurrentAuthority] = useImperativeQuery<CurrentAuthorityQuery>(
    CURRENT_AUTHORITY,
    {},
  );

  const isAuthenticated = authority !== null;

  function hasAnyRole(roles: Role[]) {
    if (!isAuthenticated) return false;
    return R.pipe(
      R.intersection(authority.roles as string[]),
      R.complement(R.isEmpty),
    )(roles);
  }

  function logout() {
    setAuthority(null);
  }

  const reloadAuthority = useCallback(async () => {
    const result = await loadCurrentAuthority();
    const nextAuthority = result.data?.currentAuthority ?? null;
    setAuthority(nextAuthority);
    return nextAuthority;
  }, [loadCurrentAuthority]);

  const updateUser = useCallback(
    (
      user: Omit<AuthorityUserPartsFragment, '__typename' | 'isSocialLogin'>,
    ): Authority => {
      if (!authority) throw new Error('No user in authority to update.');
      const nextUser = R.mergeLeft(user, authority.user);
      const nextAuthority: Authority = { ...authority, user: nextUser };
      setAuthority(nextAuthority);
      return nextAuthority;
    },
    [authority],
  );

  return (
    <AuthContext.Provider
      value={{
        authority,
        setAuthority,
        setUser: updateUser,
        isAuthenticated,
        hasAnyRole,
        logout,
        reloadAuthority,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const ctx = useContext(AuthContext);
  invariant(ctx, 'Auth context not set!');
  return ctx;
}
