import { gql, useQuery } from '@apollo/client';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { Viewer } from 'cesium';
import { Cartesian3, HeadingPitchRoll } from 'cesium';
import {
  caLocationParts,
  cesiumAssetParts,
  placementPictureParts,
  placementGeorefParts,
  placementSOPlacementParts,
} from '~/apollo/fragments';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import type { TilesetParams } from '~/components/cesium/cesiumUtils';
import { initialTilesets } from './upload/outcrop-subregions/$srId/placements';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import type {
  CaLocationPartsFragment,
  SubregionVftViewerPageQuery,
  SubregionVftViewerPageQueryVariables,
} from '~/apollo/generated/schema';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';
import { PageInner } from '~/components/vft-viewer/viewer';
import { Heading } from '~/components/common/Heading';

const SUBREGION_VFT_VIEWER_PAGE = gql`
  query SubregionVftViewerPage($id: Int!) {
    outcropSubregionList(id: $id) {
      id
      name
      outcrops {
        id
        name
        virtualOutcropModels {
          id
          name
          cesiumAsset {
            ...cesiumAssetParts
            assetToken
            location {
              ...caLocationParts
            }
            defaultCamera {
              ...caLocationParts
            }
          }
        }
        pictures {
          ...placementPictureParts
          placement {
            ...placementSOPlacementParts
          }
        }
        facies {
          id
          name
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        crossSections {
          id
          name
          georeference {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        sedimentaryLogs {
          id
          name
          georeference {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        wellLogs {
          id
          name
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        production {
          id
          name
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        reservoirModels {
          id
          name
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        trainingImages {
          id
          name
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        variograms {
          id
          name
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        gigaPans {
          id
          name
          georeference {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        fieldPictures {
          id
          file {
            ...fileParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        miniModels {
          id
          name
          url
          placement {
            ...placementSOPlacementParts
          }
        }
        photo360s {
          id
          name
          url
          placement {
            ...placementSOPlacementParts
          }
        }
        videos {
          id
          name
          url
          placement {
            ...placementSOPlacementParts
          }
        }

        studies {
          id
          name
          dataHistory {
            id
            collectedBy
            date
          }
          pictures {
            ...placementPictureParts
            placement {
              ...placementSOPlacementParts
            }
          }
          facies {
            id
            name
            outcropTagId
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
          crossSections {
            id
            name
            outcropTagId
            georeference {
              ...placementGeorefParts
            }
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
          sedimentaryLogs {
            id
            name
            outcropTagId
            georeference {
              ...placementGeorefParts
            }
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
          wellLogs {
            id
            name
            outcropTagId
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
          production {
            id
            name
            outcropTagId
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
          reservoirModels {
            id
            name
            outcropTagId
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
          trainingImages {
            id
            name
            outcropTagId
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
          variograms {
            id
            name
            outcropTagId
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
          gigaPans {
            id
            name
            outcropTagId
            georeference {
              ...placementGeorefParts
            }
            pictures {
              ...placementPictureParts
            }
            placement {
              ...placementSOPlacementParts
            }
          }
        }
      }
    }
  }

  ${cesiumAssetParts}
  ${caLocationParts}
  ${placementGeorefParts}
  ${placementPictureParts}
  ${placementSOPlacementParts}
`;

type Subregion = SubregionVftViewerPageQuery['outcropSubregionList'][number];

type VftViewerPageContextValue = {
  subregion: Subregion;
  setViewer: (viewer: Viewer) => void;
  visibleTilesets: TilesetParams[];
  viewer: Viewer | null;
  flyToLocation: (dc: CaLocationPartsFragment, index: number) => void;
  vftState: number;
  setVftState: (n: number) => void;
};

const VftViewerPageContext = createContext<VftViewerPageContextValue | null>(
  null,
);

function VftViewerPageContextProvider({
  subregion,
  children,
}: {
  subregion: Subregion;
  children: ReactNode;
}) {
  const availableTilesets = useMemo(
    () => initialTilesets(subregion),
    [subregion],
  );

  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [vftState, setVftState] = useState<number>(-1);
  const [visibleTilesets] = useState<TilesetParams[]>([...availableTilesets]);

  function flyToLocation(loc: CaLocationPartsFragment, i: number) {
    viewer?.scene.camera.flyTo({
      destination: Cartesian3.fromDegrees(
        loc.longitude,
        loc.latitude,
        loc.height,
      ),
      orientation: new HeadingPitchRoll(
        loc.heading || 0,
        loc.pitch || 0,
        loc.roll || 0,
      ),
    });
    setVftState(i);
  }

  return (
    <VftViewerPageContext.Provider
      value={{
        subregion,
        setViewer,
        viewer,
        visibleTilesets,
        setVftState,
        vftState,
        flyToLocation,
      }}
    >
      {children}
    </VftViewerPageContext.Provider>
  );
}

export function useVftViewerPageContext() {
  const ctx = useContext(VftViewerPageContext);
  invariant(ctx, 'VftViewerPageContext not initialized!');
  return ctx;
}

export default function VftViewerRoute() {
  useBreadcrumb('routes/vft-viewer', 'Virtual Fieldtrips Viewer');
  const params = useParams();
  invariant(params.subregionId, 'Subregion ID missing from route params');
  const subregionId = parseInt(params.subregionId);

  const { data, loading } = useQuery<
    SubregionVftViewerPageQuery,
    SubregionVftViewerPageQueryVariables
  >(SUBREGION_VFT_VIEWER_PAGE, {
    variables: { id: subregionId },
    pollInterval: 5000,
  });

  const subregion = data?.outcropSubregionList.find(
    sr => sr.id === subregionId,
  );

  if (loading) return <SpinnerIcon />;
  if (!subregion) return <NotFound />;

  return (
    <VftViewerPageContextProvider subregion={subregion}>
      <Heading level={2} className="text-center">
        Virtual Field Trip
      </Heading>
      <PageInner subregion={subregion} />
    </VftViewerPageContextProvider>
  );
}
