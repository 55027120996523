import { gql, useQuery } from '@apollo/client';
import { Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import type {
  UploadSubregionUpdatePageQuery,
  UploadSubregionUpdatePageQueryVariables,
} from '~/apollo/generated/schema';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { NavTabs } from '~/components/navigation/NavTabs';
import {
  uploadOutcropSubregionUpdatePlacementsRoute,
  uploadOutcropSubregionUpdateRoute,
} from '~/paths';

const UPLOAD_SUBREGION_UPDATE_PAGE = gql`
  query UploadSubregionUpdatePage($id: Int!) {
    outcropSubregionList(id: $id) {
      id
      name
    }
  }
`;

export function UploadSubregionUpdatePage() {
  const params = useParams();
  invariant(params.srId, 'Subregion ID missing from route params');
  const subregionId = parseInt(params.srId);

  const { data } = useQuery<
    UploadSubregionUpdatePageQuery,
    UploadSubregionUpdatePageQueryVariables
  >(UPLOAD_SUBREGION_UPDATE_PAGE, { variables: { id: subregionId } });

  const subregionName =
    data?.outcropSubregionList.find(sr => sr.id === subregionId)?.name ??
    'Update Subregion';

  useBreadcrumb('routes/upload/outcrop-subregions/$srId', subregionName);

  return (
    <div className="space-y-4">
      <NavTabs>
        <NavTabs.Tab to={uploadOutcropSubregionUpdateRoute(subregionId)} end>
          Outcrops
        </NavTabs.Tab>
        <NavTabs.Tab
          to={uploadOutcropSubregionUpdatePlacementsRoute(subregionId)}
        >
          SO Placements
        </NavTabs.Tab>
      </NavTabs>

      <Outlet />
    </div>
  );
}
