import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { userParts } from '~/apollo/fragments';
import type {
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables,
  UserInput,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { Heading } from '~/components/common/Heading';
import { UserProfileFormFields } from '~/components/user/UserProfileFormFields';
import { useAuth } from '~/contexts/auth';
import { envVars } from '~/environment';
import { confirmNewUsernameRoute } from '~/paths';
import type { UpdateProfileFormValues } from '~/utils/modules/user';
import { initialValues, validationSchema } from '~/utils/modules/user';

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $id: Int!
    $user: UserInput!
    $confirmUrl: String!
  ) {
    updateUser(id: $id, user: $user, confirmUrl: $confirmUrl) {
      ...userParts
    }
  }

  ${userParts}
`;

export function UpdateProfileForm() {
  const { authority, setUser } = useAuth();
  invariant(authority, 'must be authenticated');

  const currentUsername = authority.user.username;
  const companyMails = authority.user.company.mails;
  const { isSocialLogin } = authority.user;

  const [updateUser, { data, loading, error }] = useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UPDATE_USER_PROFILE, {});

  useEffect(() => {
    if (data?.updateUser) {
      setUser(data?.updateUser);
    }
  }, [data?.updateUser, setUser]);

  async function handleSubmit(values: UpdateProfileFormValues) {
    const user: UserInput = {
      name: values.name,
      username: values.username,
    };
    if (values.password?.length) {
      user.password = values.password;
    }

    try {
      if (!authority) throw new Error('Must be authenticated');

      await updateUser({
        variables: {
          id: authority.user.id,
          user,
          confirmUrl: `${envVars.VITE_CLIENT_URL}${confirmNewUsernameRoute(
            '{token}',
          )}`,
        },
      });

      const baseSuccessMsg = 'Your profile has been saved successfully.';
      const updatedUsernameMsg =
        'You will need to confirm your new email address before the changes fully take effect.';

      const successToast = (
        <>
          <p>{baseSuccessMsg}</p>
          {currentUsername !== values.username && <p>{updatedUsernameMsg}</p>}
        </>
      );

      toast.success(successToast);
    } catch (err) {
      console.log('Error updating user', err);
      toast.error('There was a problem saving your profile, please try again.');
    }
  }

  return (
    <>
      <Heading level={3}>Update Details</Heading>
      {!isSocialLogin && (
        <p>
          Review the following info for your account profile and make any
          changes desired.
        </p>
      )}
      {isSocialLogin && (
        <div className="alert alert-warning">
          Your account details are automatically synced on each login with your
          Microsoft account. To update your name or email address, please
          contact your company administrator.
        </div>
      )}

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues(authority.user)}
        validationSchema={validationSchema(currentUsername, companyMails)}
      >
        <Form className="space-y-4">
          <UserProfileFormFields
            currentUsername={currentUsername}
            companyMails={companyMails}
            disabled={isSocialLogin}
          />

          <FormErrors graphQLError={error} />

          {!isSocialLogin && (
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                Save
              </button>
            </div>
          )}
        </Form>
      </Formik>
    </>
  );
}
