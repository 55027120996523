import type { PureQueryOptions } from '@apollo/client';
import { useMutation, gql } from '@apollo/client';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import type {
  DeleteLithostratStudyLinkMutation,
  DeleteLithostratStudyLinkMutationVariables,
  UploadStudyUpdateLithostratigraphyTabQuery,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { SortTrigger } from '~/components/common/SortTrigger';
import { useSortFilter } from '~/hooks/data';
import {
  uploadLithostratGroupUpdateRoute,
  uploadLithostratFormationUpdateRoute,
  uploadLithostratMemberUpdateRoute,
} from '~/paths';

export const DELETE_LITHOSTRAT_STUDY_LINK = gql`
  mutation DeleteLithostratStudyLink($id: Int!) {
    deleteLithostratStudyLink(id: $id)
  }
`;

type LinkType =
  UploadStudyUpdateLithostratigraphyTabQuery['studyList'][number]['lithostratStudyLinks'][number];

function linkPath(link: LinkType) {
  if (!link.targetId) return '#';

  switch (link.targetType) {
    case 'group':
      return uploadLithostratGroupUpdateRoute(link.targetId);
    case 'formation':
      return uploadLithostratFormationUpdateRoute(link.targetId);
    case 'member':
      return uploadLithostratMemberUpdateRoute(link.targetId);
    default:
      return '#';
  }
}

type Props = {
  lithostratLinks: LinkType[];
  refetchQueries: PureQueryOptions[];
};

export function StudyLithostratLinksTable({
  lithostratLinks,
  refetchQueries,
}: Props) {
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    lithostratLinks,
    'targetName',
    'targetName',
    'uploadStudyLithostratLinks',
  );

  const [deleteLink, { loading }] = useMutation<
    DeleteLithostratStudyLinkMutation,
    DeleteLithostratStudyLinkMutationVariables
  >(DELETE_LITHOSTRAT_STUDY_LINK, {
    refetchQueries,
  });

  const handleDelete = (id: number) => async () => {
    try {
      await deleteLink({ variables: { id } });
      toast.success('Link deleted successfully.');
    } catch (err) {
      toast.error('There was a problem deleting the link.');
      console.log('Error deleting link:', err);
    }
  };

  return (
    <table className="table table-compact w-full">
      <thead>
        <tr>
          <th>
            <SortTrigger colName="targetName" sortIndicatorProps={siProps}>
              Name
            </SortTrigger>
          </th>
          <th>
            <SortTrigger
              colName="targetType"
              sortIndicatorProps={siProps}
              filterable
            >
              Lithostrat Type
            </SortTrigger>
          </th>
          <th />
        </tr>
      </thead>

      <tbody>
        <NoItemsRow show={!items.length} colSpan={3} />

        {items.map(link => (
          <tr key={link.id}>
            <td>
              <Link to={linkPath(link)} className="link">
                {link.targetName}
              </Link>
            </td>
            <td>{link.targetType}</td>
            <td className="text-right">
              <Confirm
                onConfirm={handleDelete(link.id)}
                title={`Remove ${link.targetType} link`}
                text={`The ${link.targetName} will be removed.`}
              >
                {onConfirm => (
                  <Button
                    type="button"
                    color="ghost"
                    size="xs"
                    onClick={onConfirm}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faLinkSlash} />
                  </Button>
                )}
              </Confirm>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
