import { Link } from 'react-router-dom';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { PageLoader } from '~/components/page/PageLoader';
import { vftViewerRoute } from '~/paths';

export default function VftRoute() {
  useBreadcrumb('routes/vft', 'Virtual Fieldtrips');

  return (
    <>
      <div className="float-right mb-2 ml-2">
        <Link to={vftViewerRoute(3)} className="btn btn-primary btn-sm">
          Outcrop Tours Preview
        </Link>
      </div>

      <PageLoader pageName="vft" />
    </>
  );
}
