import { gql, useMutation, type PureQueryOptions } from '@apollo/client';
import type { ReactNode } from 'react';
import { toast } from 'react-toastify';
import type {
  DeleteMiniModelMutation,
  DeleteMiniModelMutationVariables,
  DeletePhoto360Mutation,
  DeletePhoto360MutationVariables,
  DeleteVideoMutation,
  DeleteVideoMutationVariables,
} from '~/apollo/generated/schema';
import { Confirm } from '~/components/common/Confirm';

const DELETE_MINI_MODEL = gql`
  mutation DeleteMiniModel($id: Int!) {
    deleteMiniModel(id: $id)
  }
`;

const DELETE_PHOTO_360 = gql`
  mutation DeletePhoto360($id: Int!) {
    deletePhoto360(id: $id)
  }
`;

const DELETE_VIDEO = gql`
  mutation DeleteVideo($id: Int!) {
    deleteVideo(id: $id)
  }
`;

export function DeleteUrlBasedSO({
  children,
  soType,
  id,
  isPlaced,
  refetchQueries,
}: {
  children: (confirmDelete: () => void, loadingDelete: boolean) => ReactNode;
  soType: 'MiniModel' | 'Photo360' | 'Video';
  id: number;
  isPlaced: boolean;
  refetchQueries?: PureQueryOptions[];
}) {
  const [deleteMiniModel, { loading: loadingMM }] = useMutation<
    DeleteMiniModelMutation,
    DeleteMiniModelMutationVariables
  >(DELETE_MINI_MODEL, { variables: { id }, refetchQueries });

  const [deletePhoto360, { loading: loadingP360 }] = useMutation<
    DeletePhoto360Mutation,
    DeletePhoto360MutationVariables
  >(DELETE_PHOTO_360, { variables: { id }, refetchQueries });

  const [deleteVideo, { loading: loadingV }] = useMutation<
    DeleteVideoMutation,
    DeleteVideoMutationVariables
  >(DELETE_VIDEO, { variables: { id }, refetchQueries });

  async function handleDelete() {
    const handleError = (err: unknown) => {
      console.log(`Error deleting ${soType} ${id}`, err);
      toast.error(
        'There was a problem deleting the item, please reload the page and try again.',
      );
    };

    switch (soType) {
      case 'MiniModel':
        await deleteMiniModel().catch(handleError);
        break;
      case 'Photo360':
        await deletePhoto360().catch(handleError);
        break;
      case 'Video':
        await deleteVideo().catch(handleError);
        break;
    }

    toast.success('Item deleted successfully.');
  }

  const loading = loadingMM || loadingP360 || loadingV;

  return (
    <Confirm
      onConfirm={handleDelete}
      text={
        <div className="space-y-2">
          <p>Are you sure you want to delete this item?</p>
          {isPlaced && (
            <p className="text-error font-bold">
              This item has already been placed!
            </p>
          )}
        </div>
      }
      submitButtonColor={isPlaced ? 'error' : 'primary'}
    >
      {confirmDelete => children(confirmDelete, loading)}
    </Confirm>
  );
}
