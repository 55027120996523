import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import { Alert, Steps } from 'react-daisyui';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateCesiumAsset } from '~/components/upload/vom/modelData/CreateCesiumAsset';
import { ArchivingState } from '~/components/upload/vom/modelData/states/ArchivingState';
import { DownloadingState } from '~/components/upload/vom/modelData/states/DownloadingState';
import { InitializedState } from '~/components/upload/vom/modelData/states/InitializedState';
import { PlaceableCompleteState } from '~/components/upload/vom/modelData/states/PlaceableCompleteState';
import { ProcessingState } from '~/components/upload/vom/modelData/states/ProcessingState';
import { QualityAssuranceState } from '~/components/upload/vom/modelData/states/QualityAssuranceState';
import { UploadingState } from '~/components/upload/vom/modelData/states/UploadingState';
import type { CesiumAssetAttributesState } from '~/openapi/api-v4';
import { useGetApiV4VirtualOutcropModelsId } from '~/openapi/api-v4';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';

function steps(state: CesiumAssetAttributesState) {
  const steps = [
    ['initialized', 'Upload files'],
    ['uploading', 'Send to cesium'],
    ['processing', 'Process'],
    ['archiving', 'Archive'],
    ['downloading', 'Download'],
    ['quality_assurance', 'QA'],
  ];

  if (state === 'complete') {
    steps.push(['complete', 'Done']);
  } else {
    steps.push(['placeable', 'Place']);
  }

  return steps;
}

function Stepper({ state }: { state: CesiumAssetAttributesState }) {
  const possibleSteps = steps(state);
  const curStep = possibleSteps.findIndex(([s, _]) => s === state);

  // Upload failed or something else happened
  if (curStep === -1) {
    return null;
  }

  return (
    <Steps className="min-w-full">
      {steps(state).map(([name, label], i) => (
        <Steps.Step key={name} color={i <= curStep ? 'primary' : 'neutral'}>
          <span
            className={cn('text-sm cursor-default', {
              'text-slate-500': i > curStep,
            })}
          >
            {label}
          </span>
        </Steps.Step>
      ))}
    </Steps>
  );
}

export default function UpdateVomModelDataPage() {
  const updateVomCtx = useUpdateVomOutletContext();
  const { data, isLoading } = useGetApiV4VirtualOutcropModelsId(
    String(updateVomCtx.vom.id),
    { include: 'cesium_asset' },
  );

  const vom = data?.data;
  const vomId = vom?.id;
  const cesiumAsset = data?.included?.at(0);
  const cesiumAssetId = cesiumAsset?.id.toString() ?? null;
  const caAttributes = cesiumAsset?.attributes;
  const caState = (caAttributes && "state" in caAttributes) ? caAttributes?.state : null;

  if (isLoading) return <SpinnerPlaceholder />;
  if (!vomId) return <NotFound />;

  console.log('Vom:', vom);
  console.log('cesiumAssetId:', cesiumAssetId);
  console.log('Cesium asset:', cesiumAsset);

  return (
    <div className="space-y-4">
      {caState && <Stepper state={caState} />}
      {!cesiumAssetId && <CreateCesiumAsset vomId={vomId} />}
      {cesiumAssetId && caState && (
        <CesiumAssetState
          vomId={vomId}
          cesiumAssetId={cesiumAssetId}
          state={caState}
        />
      )}
    </div>
  );
}

function CesiumAssetState({
  vomId,
  cesiumAssetId,
  state,
}: {
  vomId: string;
  cesiumAssetId: string;
  state: CesiumAssetAttributesState;
}) {
  const props = { vomId, cesiumAssetId };

  switch (state) {
    case 'initialized':
      return <InitializedState {...props} />;
    case 'uploading':
      return <UploadingState {...props} />;
    case 'processing':
      return <ProcessingState {...props} />;
    case 'archiving':
      return <ArchivingState {...props} />;
    case 'downloading':
      return <DownloadingState {...props} />;
    case 'quality_assurance':
      return <QualityAssuranceState {...props} />;
    case 'placeable':
    case 'complete':
      return <PlaceableCompleteState {...props} state={state} />;
    case 'failed':
      return (
        <Alert status="error">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-xl" />
          <p>There was a problem in the Cesium Asset process.</p>
        </Alert>
      );
  }
}
