/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Open API Specification
 * OpenAPI spec version: 1.1
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import { customInstance } from '../axios-mutator';
export type GetApiV4VirtualOutcropModelsIdCesiumAsset200Meta = { [key: string]: unknown };

export type GetApiV4VirtualOutcropModelsIdCesiumAsset200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: GetApiV4VirtualOutcropModelsIdCesiumAsset200Meta;
};

export type GetApiV4VirtualOutcropModelsIdCesiumAssetParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type GetApiV4VirtualOutcropModelsId200Meta = { [key: string]: unknown };

export type GetApiV4VirtualOutcropModelsId200IncludedItem = CesiumAsset | CesiumLocation;

export type GetApiV4VirtualOutcropModelsId200 = {
  data?: VirtualOutcropModel;
  included?: GetApiV4VirtualOutcropModelsId200IncludedItem[];
  meta?: GetApiV4VirtualOutcropModelsId200Meta;
};

export type GetApiV4VirtualOutcropModelsIdParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for virtual_outcrop_model */
  virtual_outcrop_model?: string;
  [key: string]: unknown;
 };
};

export type GetApiV4VirtualOutcropModels200Meta = { [key: string]: unknown };

export type GetApiV4VirtualOutcropModels200IncludedItem = CesiumAsset | CesiumLocation;

export type GetApiV4VirtualOutcropModels200 = {
  /** An array of resource objects representing a virtual_outcrop_model */
  data?: VirtualOutcropModel[];
  included?: GetApiV4VirtualOutcropModels200IncludedItem[];
  meta?: GetApiV4VirtualOutcropModels200Meta;
};

export type GetApiV4VirtualOutcropModelsPageAnyOfTwo = {
  count?: boolean;
  /** @minimum 1 */
  limit?: number;
  /** @minimum 0 */
  offset?: number;
};

export type GetApiV4VirtualOutcropModelsPageAnyOf = {
  after?: string;
  before?: string;
  count?: boolean;
  /** @minimum 1 */
  limit?: number;
};

export type GetApiV4VirtualOutcropModelsParams = {
/**
 * Filters the query to results with attributes matching the given filter object
 */
filter?: VirtualOutcropModelFilter;
/**
 * Sort order to apply to the results
 */
sort?: string;
/**
 * Paginates the response with the limit and offset or keyset pagination.
 */
page?: GetApiV4VirtualOutcropModelsPageAnyOf | GetApiV4VirtualOutcropModelsPageAnyOfTwo;
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for virtual_outcrop_model */
  virtual_outcrop_model?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdUploadToCesium200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdUploadToCesium200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdUploadToCesium200Meta;
};

export type PatchApiV4CesiumAssetsIdUploadToCesiumBody = {
  data: PatchApiV4CesiumAssetsIdUploadToCesiumBodyData;
};

export type PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataType = typeof PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdUploadToCesiumBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdUploadToCesiumBodyDataType;
};

export type PatchApiV4CesiumAssetsIdUploadToCesiumParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdSetUtm200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdSetUtm200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdSetUtm200Meta;
};

export type PatchApiV4CesiumAssetsIdSetUtmBody = {
  data: PatchApiV4CesiumAssetsIdSetUtmBodyData;
};

export type PatchApiV4CesiumAssetsIdSetUtmBodyDataType = typeof PatchApiV4CesiumAssetsIdSetUtmBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdSetUtmBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdSetUtmBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdSetUtmBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdSetUtmBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdSetUtmBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdSetUtmBodyDataType;
};

export type PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributesUtmDataUtmHemisphere = typeof PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributesUtmDataUtmHemisphere[keyof typeof PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributesUtmDataUtmHemisphere];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributesUtmDataUtmHemisphere = {
  north: 'north',
  south: 'south',
} as const;

export type PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributesUtmData = {
  utm_eastings?: number;
  utm_hemisphere?: PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributesUtmDataUtmHemisphere;
  utm_northings?: number;
  utm_zone?: number;
};

export type PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributes = {
  utm_data: PatchApiV4CesiumAssetsIdSetUtmBodyDataAttributesUtmData;
};

export type PatchApiV4CesiumAssetsIdSetUtmParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdSaveDefaultCamera200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdSaveDefaultCamera200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdSaveDefaultCamera200Meta;
};

export type PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataType = typeof PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataAttributesDefaultCamera = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataAttributes = {
  default_camera: PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataAttributesDefaultCamera;
};

export type PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyDataType;
};

export type PatchApiV4CesiumAssetsIdSaveDefaultCameraBody = {
  data: PatchApiV4CesiumAssetsIdSaveDefaultCameraBodyData;
};

export type PatchApiV4CesiumAssetsIdSaveDefaultCameraParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdSaveClipping200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdSaveClipping200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdSaveClipping200Meta;
};

export type PatchApiV4CesiumAssetsIdSaveClippingBodyDataType = typeof PatchApiV4CesiumAssetsIdSaveClippingBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdSaveClippingBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdSaveClippingBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdSaveClippingBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdSaveClippingBodyDataAttributes = {
  is_clipping: boolean;
};

export type PatchApiV4CesiumAssetsIdSaveClippingBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdSaveClippingBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdSaveClippingBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdSaveClippingBodyDataType;
};

export type PatchApiV4CesiumAssetsIdSaveClippingBody = {
  data: PatchApiV4CesiumAssetsIdSaveClippingBodyData;
};

export type PatchApiV4CesiumAssetsIdSaveClippingParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdResetToInitialized200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdResetToInitialized200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdResetToInitialized200Meta;
};

export type PatchApiV4CesiumAssetsIdResetToInitializedBodyDataType = typeof PatchApiV4CesiumAssetsIdResetToInitializedBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdResetToInitializedBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdResetToInitializedBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdResetToInitializedBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdResetToInitializedBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdResetToInitializedBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdResetToInitializedBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdResetToInitializedBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdResetToInitializedBodyDataType;
};

export type PatchApiV4CesiumAssetsIdResetToInitializedBody = {
  data: PatchApiV4CesiumAssetsIdResetToInitializedBodyData;
};

export type PatchApiV4CesiumAssetsIdResetToInitializedParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdResetInitalFiles200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdResetInitalFiles200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdResetInitalFiles200Meta;
};

export type PatchApiV4CesiumAssetsIdResetInitalFilesBody = {
  data: PatchApiV4CesiumAssetsIdResetInitalFilesBodyData;
};

export type PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataType = typeof PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdResetInitalFilesBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdResetInitalFilesBodyDataType;
};

export type PatchApiV4CesiumAssetsIdResetInitalFilesParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdPollUploading200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollUploading200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdPollUploading200Meta;
};

export type PatchApiV4CesiumAssetsIdPollUploadingBody = {
  data: PatchApiV4CesiumAssetsIdPollUploadingBodyData;
};

export type PatchApiV4CesiumAssetsIdPollUploadingBodyDataType = typeof PatchApiV4CesiumAssetsIdPollUploadingBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdPollUploadingBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdPollUploadingBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdPollUploadingBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollUploadingBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollUploadingBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdPollUploadingBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdPollUploadingBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdPollUploadingBodyDataType;
};

export type PatchApiV4CesiumAssetsIdPollUploadingParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdPollProcessingAtCesium200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollProcessingAtCesium200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdPollProcessingAtCesium200Meta;
};

export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataType = typeof PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyDataType;
};

export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBody = {
  data: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBodyData;
};

export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdPollDownloading200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollDownloading200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdPollDownloading200Meta;
};

export type PatchApiV4CesiumAssetsIdPollDownloadingBody = {
  data: PatchApiV4CesiumAssetsIdPollDownloadingBodyData;
};

export type PatchApiV4CesiumAssetsIdPollDownloadingBodyDataType = typeof PatchApiV4CesiumAssetsIdPollDownloadingBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdPollDownloadingBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdPollDownloadingBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdPollDownloadingBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollDownloadingBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollDownloadingBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdPollDownloadingBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdPollDownloadingBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdPollDownloadingBodyDataType;
};

export type PatchApiV4CesiumAssetsIdPollDownloadingParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdPollArchivingAtCesium200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollArchivingAtCesium200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdPollArchivingAtCesium200Meta;
};

export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataType = typeof PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyDataType;
};

export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBody = {
  data: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBodyData;
};

export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdPlace200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPlace200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdPlace200Meta;
};

export type PatchApiV4CesiumAssetsIdPlaceBody = {
  data: PatchApiV4CesiumAssetsIdPlaceBodyData;
};

export type PatchApiV4CesiumAssetsIdPlaceBodyDataType = typeof PatchApiV4CesiumAssetsIdPlaceBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdPlaceBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdPlaceBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdPlaceBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPlaceBodyDataAttributes = {
  location: PatchApiV4CesiumAssetsIdPlaceBodyDataAttributesLocation;
};

export type PatchApiV4CesiumAssetsIdPlaceBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdPlaceBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdPlaceBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdPlaceBodyDataType;
};

export type PatchApiV4CesiumAssetsIdPlaceBodyDataAttributesLocation = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdPlaceParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PostApiV4CesiumAssetsIdMultipartStart201 = {
  token: string;
  upload_path: string;
};

export type PostApiV4CesiumAssetsIdMultipartStartBodyData = {
  content_type: string;
  file_name: string;
};

export type PostApiV4CesiumAssetsIdMultipartStartBody = {
  data: PostApiV4CesiumAssetsIdMultipartStartBodyData;
};

export type PostApiV4CesiumAssetsIdMultipartCompleteBodyDataPartsItem = { [key: string]: unknown };

export type PostApiV4CesiumAssetsIdMultipartCompleteBodyData = {
  parts: PostApiV4CesiumAssetsIdMultipartCompleteBodyDataPartsItem[];
  token: string;
};

export type PostApiV4CesiumAssetsIdMultipartCompleteBody = {
  data: PostApiV4CesiumAssetsIdMultipartCompleteBodyData;
};

export type PostApiV4CesiumAssetsIdMultipartAbortBodyData = {
  token: string;
};

export type PostApiV4CesiumAssetsIdMultipartAbortBody = {
  data: PostApiV4CesiumAssetsIdMultipartAbortBodyData;
};

export type PatchApiV4CesiumAssetsIdMarkAsCompleted200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdMarkAsCompleted200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdMarkAsCompleted200Meta;
};

export type PatchApiV4CesiumAssetsIdMarkAsCompletedBody = {
  data: PatchApiV4CesiumAssetsIdMarkAsCompletedBodyData;
};

export type PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataType = typeof PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdMarkAsCompletedBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdMarkAsCompletedBodyDataType;
};

export type PatchApiV4CesiumAssetsIdMarkAsCompletedParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type PatchApiV4CesiumAssetsIdDeleteStagedFile200Success = typeof PatchApiV4CesiumAssetsIdDeleteStagedFile200Success[keyof typeof PatchApiV4CesiumAssetsIdDeleteStagedFile200Success];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdDeleteStagedFile200Success = {
  true: true,
} as const;

export type PatchApiV4CesiumAssetsIdDeleteStagedFile200 = {
  success: PatchApiV4CesiumAssetsIdDeleteStagedFile200Success;
};

export type PatchApiV4CesiumAssetsIdDeleteStagedFileBodyData = {
  file_name: string;
};

export type PatchApiV4CesiumAssetsIdDeleteStagedFileBody = {
  data: PatchApiV4CesiumAssetsIdDeleteStagedFileBodyData;
};

export type PatchApiV4CesiumAssetsIdApproveQuality200Meta = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdApproveQuality200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PatchApiV4CesiumAssetsIdApproveQuality200Meta;
};

export type PatchApiV4CesiumAssetsIdApproveQualityBodyDataType = typeof PatchApiV4CesiumAssetsIdApproveQualityBodyDataType[keyof typeof PatchApiV4CesiumAssetsIdApproveQualityBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchApiV4CesiumAssetsIdApproveQualityBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PatchApiV4CesiumAssetsIdApproveQualityBodyDataRelationships = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdApproveQualityBodyDataAttributes = { [key: string]: unknown };

export type PatchApiV4CesiumAssetsIdApproveQualityBodyData = {
  attributes?: PatchApiV4CesiumAssetsIdApproveQualityBodyDataAttributes;
  id: string;
  relationships?: PatchApiV4CesiumAssetsIdApproveQualityBodyDataRelationships;
  type?: PatchApiV4CesiumAssetsIdApproveQualityBodyDataType;
};

export type PatchApiV4CesiumAssetsIdApproveQualityBody = {
  data: PatchApiV4CesiumAssetsIdApproveQualityBodyData;
};

export type PatchApiV4CesiumAssetsIdApproveQualityParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type GetApiV4CesiumAssetsId200Meta = { [key: string]: unknown };

export type GetApiV4CesiumAssetsId200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: GetApiV4CesiumAssetsId200Meta;
};

export type GetApiV4CesiumAssetsIdParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
/**
 * id
 */
id?: number;
};

export type GetApiV4CesiumAssetsByVomIdVirtualOutcropModelId200Meta = { [key: string]: unknown };

export type GetApiV4CesiumAssetsByVomIdVirtualOutcropModelId200 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: GetApiV4CesiumAssetsByVomIdVirtualOutcropModelId200Meta;
};

export type GetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
/**
 * virtual_outcrop_model_id
 */
virtual_outcrop_model_id?: number;
};

export type PostApiV4CesiumAssets201Meta = { [key: string]: unknown };

export type PostApiV4CesiumAssets201 = {
  data?: CesiumAsset;
  included?: CesiumLocation[];
  meta?: PostApiV4CesiumAssets201Meta;
};

export type PostApiV4CesiumAssetsBodyDataType = typeof PostApiV4CesiumAssetsBodyDataType[keyof typeof PostApiV4CesiumAssetsBodyDataType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApiV4CesiumAssetsBodyDataType = {
  cesium_asset: 'cesium_asset',
} as const;

export type PostApiV4CesiumAssetsBodyDataRelationships = { [key: string]: unknown };

export type PostApiV4CesiumAssetsBodyDataAttributes = {
  vom_id: number;
};

export type PostApiV4CesiumAssetsBodyData = {
  attributes?: PostApiV4CesiumAssetsBodyDataAttributes;
  relationships?: PostApiV4CesiumAssetsBodyDataRelationships;
  type?: PostApiV4CesiumAssetsBodyDataType;
};

export type PostApiV4CesiumAssetsBody = {
  data: PostApiV4CesiumAssetsBodyData;
};

export type PostApiV4CesiumAssetsParams = {
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

export type GetApiV4CesiumAssets200Meta = { [key: string]: unknown };

export type GetApiV4CesiumAssets200 = {
  /** An array of resource objects representing a cesium_asset */
  data?: CesiumAsset[];
  included?: CesiumLocation[];
  meta?: GetApiV4CesiumAssets200Meta;
};

export type GetApiV4CesiumAssetsParams = {
/**
 * Filters the query to results with attributes matching the given filter object
 */
filter?: CesiumAssetFilter;
/**
 * Sort order to apply to the results
 */
sort?: string;
/**
 * Paginates the response with the limit and offset or keyset pagination.
 */
page: {
  after?: string;
  before?: string;
  /** @minimum 1 */
  limit?: number;
};
/**
 * Relationship paths to include in the response
 */
include?: string;
/**
 * Limits the response fields to only those listed for each type
 */
fields?: {
  /** Comma separated field names for cesium_asset */
  cesium_asset?: string;
  [key: string]: unknown;
 };
};

/**
 * General Error
 */
export type ErrorsResponse = Errors;

export interface CesiumLocationFilterPitch {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

/**
 * Filters the query to results matching the given filter object
 */
export interface VirtualOutcropModelFilter {
  and?: VirtualOutcropModelFilter;
  cesium_asset?: CesiumAssetFilter;
  id?: VirtualOutcropModelFilterId;
  name?: VirtualOutcropModelFilterName;
  not?: VirtualOutcropModelFilter;
  or?: VirtualOutcropModelFilter;
}

export interface CesiumAssetFilterLocationId {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface VirtualOutcropModelFilterName {
  eq?: string;
  greater_than?: string;
  greater_than_or_equal?: string;
  ilike?: string;
  in?: string[];
  is_nil?: boolean;
  less_than?: string;
  less_than_or_equal?: string;
  like?: string;
  not_eq?: string;
}

export interface CesiumAssetFilterCesiumUploadTotal {
  in?: number[];
  is_nil?: boolean;
}

export interface CesiumAssetFilterCesiumUploadTodo {
  in?: number[];
  is_nil?: boolean;
}

export interface CesiumAssetFilterId {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface CesiumAssetFilterApproved {
  eq?: boolean;
  greater_than?: boolean;
  greater_than_or_equal?: boolean;
  in?: boolean[];
  is_nil?: boolean;
  less_than?: boolean;
  less_than_or_equal?: boolean;
  not_eq?: boolean;
}

export interface CesiumLocationFilterId {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface CesiumLocationFilterHeading {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

/**
 * A "Resource object" representing a virtual_outcrop_model
 */
export interface VirtualOutcropModel {
  /** An attributes object for a virtual_outcrop_model */
  attributes?: VirtualOutcropModelAttributes;
  id: string;
  /** A relationships object for a virtual_outcrop_model */
  relationships?: VirtualOutcropModelRelationships;
  type: string;
}

export type VirtualOutcropModelRelationshipsCesiumAssetDataItemMeta = { [key: string]: unknown };

/**
 * Resource identifiers for cesium_asset
 */
export type VirtualOutcropModelRelationshipsCesiumAssetDataItem = {
  id: string;
  meta?: VirtualOutcropModelRelationshipsCesiumAssetDataItemMeta;
  type: string;
};

export type VirtualOutcropModelRelationshipsCesiumAsset = {
  /** An array of inputs for cesium_asset */
  data?: VirtualOutcropModelRelationshipsCesiumAssetDataItem[];
};

/**
 * A relationships object for a virtual_outcrop_model
 */
export type VirtualOutcropModelRelationships = {
  cesium_asset?: VirtualOutcropModelRelationshipsCesiumAsset;
};

/**
 * An attributes object for a virtual_outcrop_model
 */
export type VirtualOutcropModelAttributes = {
  /**
   * Field included by default.
   * @nullable
   */
  name?: string | null;
};

export interface VirtualOutcropModelFilterId {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface CesiumAssetFilterCesiumAssetId {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface CesiumLocationFilterHeight {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface CesiumAssetFilterError {
  eq?: string;
  greater_than?: string;
  greater_than_or_equal?: string;
  ilike?: string;
  in?: string[];
  is_nil?: boolean;
  less_than?: string;
  less_than_or_equal?: string;
  like?: string;
  not_eq?: string;
}

/**
 * Filters the query to results matching the given filter object
 */
export interface CesiumAssetFilter {
  and?: CesiumAssetFilter;
  approved?: CesiumAssetFilterApproved;
  cesium_asset_id?: CesiumAssetFilterCesiumAssetId;
  cesium_download_todo?: CesiumAssetFilterCesiumDownloadTodo;
  cesium_download_total?: CesiumAssetFilterCesiumDownloadTotal;
  cesium_progress?: CesiumAssetFilterCesiumProgress;
  cesium_upload_todo?: CesiumAssetFilterCesiumUploadTodo;
  cesium_upload_total?: CesiumAssetFilterCesiumUploadTotal;
  default_camera?: CesiumLocationFilter;
  default_camera_id?: CesiumAssetFilterDefaultCameraId;
  error?: CesiumAssetFilterError;
  id?: CesiumAssetFilterId;
  is_clipping?: CesiumAssetFilterIsClipping;
  location?: CesiumLocationFilter;
  location_id?: CesiumAssetFilterLocationId;
  not?: CesiumAssetFilter;
  or?: CesiumAssetFilter;
  state?: CesiumAssetFilterState;
  tileset_url?: CesiumAssetFilterTilesetUrl;
}

export type ErrorSource = {
  /** A string indicating which query parameter caused the error. */
  parameter?: string;
  /** A JSON Pointer [RFC6901] to the associated entity in the request document [e.g. "/data" for a primary data object, or "/data/attributes/title" for a specific attribute]. */
  pointer?: string;
};

export interface Error {
  /** An application-specific error code, expressed as a string value. */
  code?: string;
  /** A human-readable explanation specific to this occurrence of the problem. */
  detail?: string;
  /** A unique identifier for this particular occurrence of the problem. */
  id?: string;
  links?: Links;
  source?: ErrorSource;
  /** The HTTP status code applicable to this problem, expressed as a string value. */
  status?: string;
  /** A short, human-readable summary of the problem. It SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization. */
  title?: string;
}

export type Errors = Error[];

/**
 * Filters the query to results matching the given filter object
 */
export interface CesiumLocationFilter {
  and?: CesiumLocationFilter;
  heading?: CesiumLocationFilterHeading;
  height?: CesiumLocationFilterHeight;
  id?: CesiumLocationFilterId;
  latitude?: CesiumLocationFilterLatitude;
  longitude?: CesiumLocationFilterLongitude;
  not?: CesiumLocationFilter;
  or?: CesiumLocationFilter;
  pitch?: CesiumLocationFilterPitch;
  roll?: CesiumLocationFilterRoll;
}

export interface CesiumAssetFilterCesiumDownloadTotal {
  in?: number[];
  is_nil?: boolean;
}

/**
 * A relationships object for a cesium_asset
 */
export type CesiumAssetRelationships = {
  default_camera?: CesiumAssetRelationshipsDefaultCamera;
  location?: CesiumAssetRelationshipsLocation;
};

/**
 * A "Resource object" representing a cesium_asset
 */
export interface CesiumAsset {
  /** An attributes object for a cesium_asset */
  attributes?: CesiumAssetAttributes;
  id: string;
  /** A relationships object for a cesium_asset */
  relationships?: CesiumAssetRelationships;
  type: string;
}

export type CesiumAssetRelationshipsLocationDataItemMeta = { [key: string]: unknown };

/**
 * Resource identifiers for location
 */
export type CesiumAssetRelationshipsLocationDataItem = {
  id: string;
  meta?: CesiumAssetRelationshipsLocationDataItemMeta;
  type: string;
};

export type CesiumAssetRelationshipsLocation = {
  /** An array of inputs for location */
  data?: CesiumAssetRelationshipsLocationDataItem[];
};

export type CesiumAssetRelationshipsDefaultCameraDataItemMeta = { [key: string]: unknown };

/**
 * Resource identifiers for default_camera
 */
export type CesiumAssetRelationshipsDefaultCameraDataItem = {
  id: string;
  meta?: CesiumAssetRelationshipsDefaultCameraDataItemMeta;
  type: string;
};

export type CesiumAssetRelationshipsDefaultCamera = {
  /** An array of inputs for default_camera */
  data?: CesiumAssetRelationshipsDefaultCameraDataItem[];
};

/**
 * Field included by default.
 * @nullable
 */
export type CesiumAssetAttributesUtmDataUtmHemisphere = typeof CesiumAssetAttributesUtmDataUtmHemisphere[keyof typeof CesiumAssetAttributesUtmDataUtmHemisphere] | null;


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CesiumAssetAttributesUtmDataUtmHemisphere = {
  north: 'north',
  south: 'south',
} as const;

/**
 * Field included by default.
 * @nullable
 */
export type CesiumAssetAttributesUtmData = {
  /**
   * Field included by default.
   * @nullable
   */
  utm_eastings?: number | null;
  /**
   * Field included by default.
   * @nullable
   */
  utm_hemisphere?: CesiumAssetAttributesUtmDataUtmHemisphere;
  /**
   * Field included by default.
   * @nullable
   */
  utm_northings?: number | null;
  /**
   * Field included by default.
   * @nullable
   */
  utm_zone?: number | null;
} | null;

/**
 * Field included by default.
 */
export type CesiumAssetAttributesState = typeof CesiumAssetAttributesState[keyof typeof CesiumAssetAttributesState];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CesiumAssetAttributesState = {
  failed: 'failed',
  quality_assurance: 'quality_assurance',
  initialized: 'initialized',
  uploading: 'uploading',
  processing: 'processing',
  archiving: 'archiving',
  downloading: 'downloading',
  placeable: 'placeable',
  complete: 'complete',
} as const;

/**
 * An attributes object for a cesium_asset
 */
export type CesiumAssetAttributes = {
  /**
   * Field included by default.
   * @nullable
   */
  approved?: boolean | null;
  /**
   * Field included by default.
   * @nullable
   */
  cesium_asset_id?: number | null;
  cesium_download_todo?: number;
  cesium_download_total?: number;
  /**
   * Field included by default.
   * @nullable
   */
  cesium_progress?: number | null;
  cesium_upload_todo?: number;
  cesium_upload_total?: number;
  /**
   * Field included by default.
   * @nullable
   */
  default_camera_id?: number | null;
  /**
   * Field included by default.
   * @nullable
   */
  error?: string | null;
  /**
   * Field included by default.
   * @nullable
   */
  is_clipping?: boolean | null;
  /**
   * Field included by default.
   * @nullable
   */
  location_id?: number | null;
  /** Field included by default. */
  state: CesiumAssetAttributesState;
  /**
   * Field included by default.
   * @nullable
   */
  tileset_url?: string | null;
  /**
   * Field included by default.
   * @nullable
   */
  utm_data?: CesiumAssetAttributesUtmData;
};

/**
 * A link MUST be represented as either: a string containing the link's URL or a link object.
 */
export type Link = string;

export interface CesiumAssetFilterTilesetUrl {
  eq?: string;
  greater_than?: string;
  greater_than_or_equal?: string;
  ilike?: string;
  in?: string[];
  is_nil?: boolean;
  less_than?: string;
  less_than_or_equal?: string;
  like?: string;
  not_eq?: string;
}

export interface CesiumAssetFilterCesiumDownloadTodo {
  in?: number[];
  is_nil?: boolean;
}

export interface CesiumAssetFilterCesiumProgress {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface Links {[key: string]: Link}

export interface CesiumAssetFilterIsClipping {
  eq?: boolean;
  greater_than?: boolean;
  greater_than_or_equal?: boolean;
  in?: boolean[];
  is_nil?: boolean;
  less_than?: boolean;
  less_than_or_equal?: boolean;
  not_eq?: boolean;
}

export interface CesiumLocationFilterLatitude {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface CesiumLocationFilterLongitude {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

export interface CesiumAssetFilterDefaultCameraId {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}

/**
 * A relationships object for a cesium_location
 */
export type CesiumLocationRelationships = { [key: string]: unknown };

/**
 * An attributes object for a cesium_location
 */
export type CesiumLocationAttributes = {
  /** Field included by default. */
  heading: number;
  /** Field included by default. */
  height: number;
  /** Field included by default. */
  latitude: number;
  /** Field included by default. */
  longitude: number;
  /** Field included by default. */
  pitch: number;
  /** Field included by default. */
  roll: number;
};

/**
 * A "Resource object" representing a cesium_location
 */
export interface CesiumLocation {
  /** An attributes object for a cesium_location */
  attributes?: CesiumLocationAttributes;
  id: string;
  /** A relationships object for a cesium_location */
  relationships?: CesiumLocationRelationships;
  type: string;
}

export type CesiumAssetFilterStateNotEq = typeof CesiumAssetFilterStateNotEq[keyof typeof CesiumAssetFilterStateNotEq];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CesiumAssetFilterStateNotEq = {
  failed: 'failed',
  quality_assurance: 'quality_assurance',
  initialized: 'initialized',
  uploading: 'uploading',
  processing: 'processing',
  archiving: 'archiving',
  downloading: 'downloading',
  placeable: 'placeable',
  complete: 'complete',
} as const;

export type CesiumAssetFilterStateLessThanOrEqual = typeof CesiumAssetFilterStateLessThanOrEqual[keyof typeof CesiumAssetFilterStateLessThanOrEqual];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CesiumAssetFilterStateLessThanOrEqual = {
  failed: 'failed',
  quality_assurance: 'quality_assurance',
  initialized: 'initialized',
  uploading: 'uploading',
  processing: 'processing',
  archiving: 'archiving',
  downloading: 'downloading',
  placeable: 'placeable',
  complete: 'complete',
} as const;

export type CesiumAssetFilterStateLessThan = typeof CesiumAssetFilterStateLessThan[keyof typeof CesiumAssetFilterStateLessThan];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CesiumAssetFilterStateLessThan = {
  failed: 'failed',
  quality_assurance: 'quality_assurance',
  initialized: 'initialized',
  uploading: 'uploading',
  processing: 'processing',
  archiving: 'archiving',
  downloading: 'downloading',
  placeable: 'placeable',
  complete: 'complete',
} as const;

export type CesiumAssetFilterStateGreaterThanOrEqual = typeof CesiumAssetFilterStateGreaterThanOrEqual[keyof typeof CesiumAssetFilterStateGreaterThanOrEqual];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CesiumAssetFilterStateGreaterThanOrEqual = {
  failed: 'failed',
  quality_assurance: 'quality_assurance',
  initialized: 'initialized',
  uploading: 'uploading',
  processing: 'processing',
  archiving: 'archiving',
  downloading: 'downloading',
  placeable: 'placeable',
  complete: 'complete',
} as const;

export type CesiumAssetFilterStateGreaterThan = typeof CesiumAssetFilterStateGreaterThan[keyof typeof CesiumAssetFilterStateGreaterThan];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CesiumAssetFilterStateGreaterThan = {
  failed: 'failed',
  quality_assurance: 'quality_assurance',
  initialized: 'initialized',
  uploading: 'uploading',
  processing: 'processing',
  archiving: 'archiving',
  downloading: 'downloading',
  placeable: 'placeable',
  complete: 'complete',
} as const;

export type CesiumAssetFilterStateEq = typeof CesiumAssetFilterStateEq[keyof typeof CesiumAssetFilterStateEq];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CesiumAssetFilterStateEq = {
  failed: 'failed',
  quality_assurance: 'quality_assurance',
  initialized: 'initialized',
  uploading: 'uploading',
  processing: 'processing',
  archiving: 'archiving',
  downloading: 'downloading',
  placeable: 'placeable',
  complete: 'complete',
} as const;

export interface CesiumAssetFilterState {
  eq?: CesiumAssetFilterStateEq;
  greater_than?: CesiumAssetFilterStateGreaterThan;
  greater_than_or_equal?: CesiumAssetFilterStateGreaterThanOrEqual;
  in?: string[];
  is_nil?: boolean;
  less_than?: CesiumAssetFilterStateLessThan;
  less_than_or_equal?: CesiumAssetFilterStateLessThanOrEqual;
  not_eq?: CesiumAssetFilterStateNotEq;
}

export interface CesiumLocationFilterRoll {
  eq?: number;
  greater_than?: number;
  greater_than_or_equal?: number;
  in?: number[];
  is_nil?: boolean;
  less_than?: number;
  less_than_or_equal?: number;
  not_eq?: number;
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * /cesium_assets operation on cesium_asset resource
 */
export const getApiV4CesiumAssets = (
    params: GetApiV4CesiumAssetsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetApiV4CesiumAssets200>(
      {url: `/api/v4/cesium_assets`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiV4CesiumAssetsQueryKey = (params: GetApiV4CesiumAssetsParams,) => {
    return [`/api/v4/cesium_assets`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV4CesiumAssetsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV4CesiumAssets>>, TError = ErrorsResponse>(params: GetApiV4CesiumAssetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssets>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV4CesiumAssetsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV4CesiumAssets>>> = ({ signal }) => getApiV4CesiumAssets(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssets>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV4CesiumAssetsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV4CesiumAssets>>>
export type GetApiV4CesiumAssetsQueryError = ErrorsResponse

export const useGetApiV4CesiumAssets = <TData = Awaited<ReturnType<typeof getApiV4CesiumAssets>>, TError = ErrorsResponse>(
 params: GetApiV4CesiumAssetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssets>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV4CesiumAssetsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * /cesium_assets operation on cesium_asset resource
 */
export const postApiV4CesiumAssets = (
    postApiV4CesiumAssetsBody: PostApiV4CesiumAssetsBody,
    params?: PostApiV4CesiumAssetsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostApiV4CesiumAssets201>(
      {url: `/api/v4/cesium_assets`, method: 'POST',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: postApiV4CesiumAssetsBody,
        params
    },
      options);
    }
  


export const getPostApiV4CesiumAssetsMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssets>>, TError,{data: PostApiV4CesiumAssetsBody;params?: PostApiV4CesiumAssetsParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssets>>, TError,{data: PostApiV4CesiumAssetsBody;params?: PostApiV4CesiumAssetsParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV4CesiumAssets>>, {data: PostApiV4CesiumAssetsBody;params?: PostApiV4CesiumAssetsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postApiV4CesiumAssets(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV4CesiumAssetsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV4CesiumAssets>>>
    export type PostApiV4CesiumAssetsMutationBody = PostApiV4CesiumAssetsBody
    export type PostApiV4CesiumAssetsMutationError = ErrorsResponse

    export const usePostApiV4CesiumAssets = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssets>>, TError,{data: PostApiV4CesiumAssetsBody;params?: PostApiV4CesiumAssetsParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV4CesiumAssets>>,
        TError,
        {data: PostApiV4CesiumAssetsBody;params?: PostApiV4CesiumAssetsParams},
        TContext
      > => {

      const mutationOptions = getPostApiV4CesiumAssetsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/by_vom_id/:virtual_outcrop_model_id operation on cesium_asset resource
 */
export const getApiV4CesiumAssetsByVomIdVirtualOutcropModelId = (
    virtualOutcropModelId: string,
    params?: GetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetApiV4CesiumAssetsByVomIdVirtualOutcropModelId200>(
      {url: `/api/v4/cesium_assets/by_vom_id/${virtualOutcropModelId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdQueryKey = (virtualOutcropModelId: string,
    params?: GetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdParams,) => {
    return [`/api/v4/cesium_assets/by_vom_id/${virtualOutcropModelId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV4CesiumAssetsByVomIdVirtualOutcropModelId>>, TError = ErrorsResponse>(virtualOutcropModelId: string,
    params?: GetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsByVomIdVirtualOutcropModelId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdQueryKey(virtualOutcropModelId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV4CesiumAssetsByVomIdVirtualOutcropModelId>>> = ({ signal }) => getApiV4CesiumAssetsByVomIdVirtualOutcropModelId(virtualOutcropModelId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(virtualOutcropModelId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsByVomIdVirtualOutcropModelId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV4CesiumAssetsByVomIdVirtualOutcropModelId>>>
export type GetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdQueryError = ErrorsResponse

export const useGetApiV4CesiumAssetsByVomIdVirtualOutcropModelId = <TData = Awaited<ReturnType<typeof getApiV4CesiumAssetsByVomIdVirtualOutcropModelId>>, TError = ErrorsResponse>(
 virtualOutcropModelId: string,
    params?: GetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsByVomIdVirtualOutcropModelId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV4CesiumAssetsByVomIdVirtualOutcropModelIdQueryOptions(virtualOutcropModelId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * /cesium_assets/:id operation on cesium_asset resource
 */
export const getApiV4CesiumAssetsId = (
    id: string,
    params?: GetApiV4CesiumAssetsIdParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetApiV4CesiumAssetsId200>(
      {url: `/api/v4/cesium_assets/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiV4CesiumAssetsIdQueryKey = (id: string,
    params?: GetApiV4CesiumAssetsIdParams,) => {
    return [`/api/v4/cesium_assets/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV4CesiumAssetsIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV4CesiumAssetsId>>, TError = ErrorsResponse>(id: string,
    params?: GetApiV4CesiumAssetsIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV4CesiumAssetsIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV4CesiumAssetsId>>> = ({ signal }) => getApiV4CesiumAssetsId(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV4CesiumAssetsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV4CesiumAssetsId>>>
export type GetApiV4CesiumAssetsIdQueryError = ErrorsResponse

export const useGetApiV4CesiumAssetsId = <TData = Awaited<ReturnType<typeof getApiV4CesiumAssetsId>>, TError = ErrorsResponse>(
 id: string,
    params?: GetApiV4CesiumAssetsIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV4CesiumAssetsIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * /cesium_assets/:id/approve_quality operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdApproveQuality = (
    id: string,
    patchApiV4CesiumAssetsIdApproveQualityBody?: PatchApiV4CesiumAssetsIdApproveQualityBody,
    params?: PatchApiV4CesiumAssetsIdApproveQualityParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdApproveQuality200>(
      {url: `/api/v4/cesium_assets/${id}/approve_quality`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdApproveQualityBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdApproveQualityMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdApproveQuality>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdApproveQualityBody;params?: PatchApiV4CesiumAssetsIdApproveQualityParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdApproveQuality>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdApproveQualityBody;params?: PatchApiV4CesiumAssetsIdApproveQualityParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdApproveQuality>>, {id: string;data: PatchApiV4CesiumAssetsIdApproveQualityBody;params?: PatchApiV4CesiumAssetsIdApproveQualityParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdApproveQuality(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdApproveQualityMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdApproveQuality>>>
    export type PatchApiV4CesiumAssetsIdApproveQualityMutationBody = PatchApiV4CesiumAssetsIdApproveQualityBody
    export type PatchApiV4CesiumAssetsIdApproveQualityMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdApproveQuality = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdApproveQuality>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdApproveQualityBody;params?: PatchApiV4CesiumAssetsIdApproveQualityParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdApproveQuality>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdApproveQualityBody;params?: PatchApiV4CesiumAssetsIdApproveQualityParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdApproveQualityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/delete_staged_file operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdDeleteStagedFile = (
    id: string,
    patchApiV4CesiumAssetsIdDeleteStagedFileBody: PatchApiV4CesiumAssetsIdDeleteStagedFileBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdDeleteStagedFile200>(
      {url: `/api/v4/cesium_assets/${id}/delete_staged_file`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdDeleteStagedFileBody
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdDeleteStagedFileMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdDeleteStagedFile>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdDeleteStagedFileBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdDeleteStagedFile>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdDeleteStagedFileBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdDeleteStagedFile>>, {id: string;data: PatchApiV4CesiumAssetsIdDeleteStagedFileBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchApiV4CesiumAssetsIdDeleteStagedFile(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdDeleteStagedFileMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdDeleteStagedFile>>>
    export type PatchApiV4CesiumAssetsIdDeleteStagedFileMutationBody = PatchApiV4CesiumAssetsIdDeleteStagedFileBody
    export type PatchApiV4CesiumAssetsIdDeleteStagedFileMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdDeleteStagedFile = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdDeleteStagedFile>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdDeleteStagedFileBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdDeleteStagedFile>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdDeleteStagedFileBody},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdDeleteStagedFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/list_staged_files operation on cesium_asset resource
 */
export const getApiV4CesiumAssetsIdListStagedFiles = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<string[]>(
      {url: `/api/v4/cesium_assets/${id}/list_staged_files`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiV4CesiumAssetsIdListStagedFilesQueryKey = (id: string,) => {
    return [`/api/v4/cesium_assets/${id}/list_staged_files`] as const;
    }

    
export const getGetApiV4CesiumAssetsIdListStagedFilesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV4CesiumAssetsIdListStagedFiles>>, TError = ErrorsResponse>(id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsIdListStagedFiles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV4CesiumAssetsIdListStagedFilesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV4CesiumAssetsIdListStagedFiles>>> = ({ signal }) => getApiV4CesiumAssetsIdListStagedFiles(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsIdListStagedFiles>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV4CesiumAssetsIdListStagedFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV4CesiumAssetsIdListStagedFiles>>>
export type GetApiV4CesiumAssetsIdListStagedFilesQueryError = ErrorsResponse

export const useGetApiV4CesiumAssetsIdListStagedFiles = <TData = Awaited<ReturnType<typeof getApiV4CesiumAssetsIdListStagedFiles>>, TError = ErrorsResponse>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4CesiumAssetsIdListStagedFiles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV4CesiumAssetsIdListStagedFilesQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Marks Cesium Asset as completed, requires `location`and `default_camera` attributes to be set beforehand.
 */
export const patchApiV4CesiumAssetsIdMarkAsCompleted = (
    id: string,
    patchApiV4CesiumAssetsIdMarkAsCompletedBody?: PatchApiV4CesiumAssetsIdMarkAsCompletedBody,
    params?: PatchApiV4CesiumAssetsIdMarkAsCompletedParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdMarkAsCompleted200>(
      {url: `/api/v4/cesium_assets/${id}/mark_as_completed`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdMarkAsCompletedBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdMarkAsCompletedMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdMarkAsCompleted>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdMarkAsCompletedBody;params?: PatchApiV4CesiumAssetsIdMarkAsCompletedParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdMarkAsCompleted>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdMarkAsCompletedBody;params?: PatchApiV4CesiumAssetsIdMarkAsCompletedParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdMarkAsCompleted>>, {id: string;data: PatchApiV4CesiumAssetsIdMarkAsCompletedBody;params?: PatchApiV4CesiumAssetsIdMarkAsCompletedParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdMarkAsCompleted(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdMarkAsCompletedMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdMarkAsCompleted>>>
    export type PatchApiV4CesiumAssetsIdMarkAsCompletedMutationBody = PatchApiV4CesiumAssetsIdMarkAsCompletedBody
    export type PatchApiV4CesiumAssetsIdMarkAsCompletedMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdMarkAsCompleted = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdMarkAsCompleted>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdMarkAsCompletedBody;params?: PatchApiV4CesiumAssetsIdMarkAsCompletedParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdMarkAsCompleted>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdMarkAsCompletedBody;params?: PatchApiV4CesiumAssetsIdMarkAsCompletedParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdMarkAsCompletedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/multipart_abort operation on cesium_asset resource
 */
export const postApiV4CesiumAssetsIdMultipartAbort = (
    id: string,
    postApiV4CesiumAssetsIdMultipartAbortBody: PostApiV4CesiumAssetsIdMultipartAbortBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<boolean>(
      {url: `/api/v4/cesium_assets/${id}/multipart_abort`, method: 'POST',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: postApiV4CesiumAssetsIdMultipartAbortBody
    },
      options);
    }
  


export const getPostApiV4CesiumAssetsIdMultipartAbortMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartAbort>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartAbortBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartAbort>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartAbortBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartAbort>>, {id: string;data: PostApiV4CesiumAssetsIdMultipartAbortBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postApiV4CesiumAssetsIdMultipartAbort(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV4CesiumAssetsIdMultipartAbortMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartAbort>>>
    export type PostApiV4CesiumAssetsIdMultipartAbortMutationBody = PostApiV4CesiumAssetsIdMultipartAbortBody
    export type PostApiV4CesiumAssetsIdMultipartAbortMutationError = ErrorsResponse

    export const usePostApiV4CesiumAssetsIdMultipartAbort = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartAbort>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartAbortBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartAbort>>,
        TError,
        {id: string;data: PostApiV4CesiumAssetsIdMultipartAbortBody},
        TContext
      > => {

      const mutationOptions = getPostApiV4CesiumAssetsIdMultipartAbortMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/multipart_complete operation on cesium_asset resource
 */
export const postApiV4CesiumAssetsIdMultipartComplete = (
    id: string,
    postApiV4CesiumAssetsIdMultipartCompleteBody: PostApiV4CesiumAssetsIdMultipartCompleteBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<boolean>(
      {url: `/api/v4/cesium_assets/${id}/multipart_complete`, method: 'POST',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: postApiV4CesiumAssetsIdMultipartCompleteBody
    },
      options);
    }
  


export const getPostApiV4CesiumAssetsIdMultipartCompleteMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartComplete>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartCompleteBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartComplete>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartCompleteBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartComplete>>, {id: string;data: PostApiV4CesiumAssetsIdMultipartCompleteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postApiV4CesiumAssetsIdMultipartComplete(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV4CesiumAssetsIdMultipartCompleteMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartComplete>>>
    export type PostApiV4CesiumAssetsIdMultipartCompleteMutationBody = PostApiV4CesiumAssetsIdMultipartCompleteBody
    export type PostApiV4CesiumAssetsIdMultipartCompleteMutationError = ErrorsResponse

    export const usePostApiV4CesiumAssetsIdMultipartComplete = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartComplete>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartCompleteBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartComplete>>,
        TError,
        {id: string;data: PostApiV4CesiumAssetsIdMultipartCompleteBody},
        TContext
      > => {

      const mutationOptions = getPostApiV4CesiumAssetsIdMultipartCompleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/multipart_start operation on cesium_asset resource
 */
export const postApiV4CesiumAssetsIdMultipartStart = (
    id: string,
    postApiV4CesiumAssetsIdMultipartStartBody: PostApiV4CesiumAssetsIdMultipartStartBody,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PostApiV4CesiumAssetsIdMultipartStart201>(
      {url: `/api/v4/cesium_assets/${id}/multipart_start`, method: 'POST',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: postApiV4CesiumAssetsIdMultipartStartBody
    },
      options);
    }
  


export const getPostApiV4CesiumAssetsIdMultipartStartMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartStart>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartStartBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartStart>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartStartBody}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartStart>>, {id: string;data: PostApiV4CesiumAssetsIdMultipartStartBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postApiV4CesiumAssetsIdMultipartStart(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV4CesiumAssetsIdMultipartStartMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartStart>>>
    export type PostApiV4CesiumAssetsIdMultipartStartMutationBody = PostApiV4CesiumAssetsIdMultipartStartBody
    export type PostApiV4CesiumAssetsIdMultipartStartMutationError = ErrorsResponse

    export const usePostApiV4CesiumAssetsIdMultipartStart = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartStart>>, TError,{id: string;data: PostApiV4CesiumAssetsIdMultipartStartBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV4CesiumAssetsIdMultipartStart>>,
        TError,
        {id: string;data: PostApiV4CesiumAssetsIdMultipartStartBody},
        TContext
      > => {

      const mutationOptions = getPostApiV4CesiumAssetsIdMultipartStartMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/place operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdPlace = (
    id: string,
    patchApiV4CesiumAssetsIdPlaceBody: PatchApiV4CesiumAssetsIdPlaceBody,
    params?: PatchApiV4CesiumAssetsIdPlaceParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdPlace200>(
      {url: `/api/v4/cesium_assets/${id}/place`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdPlaceBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdPlaceMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPlace>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPlaceBody;params?: PatchApiV4CesiumAssetsIdPlaceParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPlace>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPlaceBody;params?: PatchApiV4CesiumAssetsIdPlaceParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPlace>>, {id: string;data: PatchApiV4CesiumAssetsIdPlaceBody;params?: PatchApiV4CesiumAssetsIdPlaceParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdPlace(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdPlaceMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPlace>>>
    export type PatchApiV4CesiumAssetsIdPlaceMutationBody = PatchApiV4CesiumAssetsIdPlaceBody
    export type PatchApiV4CesiumAssetsIdPlaceMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdPlace = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPlace>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPlaceBody;params?: PatchApiV4CesiumAssetsIdPlaceParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPlace>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdPlaceBody;params?: PatchApiV4CesiumAssetsIdPlaceParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdPlaceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/poll_archiving_at_cesium operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdPollArchivingAtCesium = (
    id: string,
    patchApiV4CesiumAssetsIdPollArchivingAtCesiumBody?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBody,
    params?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdPollArchivingAtCesium200>(
      {url: `/api/v4/cesium_assets/${id}/poll_archiving_at_cesium`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdPollArchivingAtCesiumBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdPollArchivingAtCesiumMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollArchivingAtCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollArchivingAtCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollArchivingAtCesium>>, {id: string;data: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdPollArchivingAtCesium(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollArchivingAtCesium>>>
    export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumMutationBody = PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBody
    export type PatchApiV4CesiumAssetsIdPollArchivingAtCesiumMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdPollArchivingAtCesium = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollArchivingAtCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollArchivingAtCesium>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollArchivingAtCesiumParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdPollArchivingAtCesiumMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/poll_downloading operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdPollDownloading = (
    id: string,
    patchApiV4CesiumAssetsIdPollDownloadingBody?: PatchApiV4CesiumAssetsIdPollDownloadingBody,
    params?: PatchApiV4CesiumAssetsIdPollDownloadingParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdPollDownloading200>(
      {url: `/api/v4/cesium_assets/${id}/poll_downloading`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdPollDownloadingBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdPollDownloadingMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollDownloading>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollDownloadingBody;params?: PatchApiV4CesiumAssetsIdPollDownloadingParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollDownloading>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollDownloadingBody;params?: PatchApiV4CesiumAssetsIdPollDownloadingParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollDownloading>>, {id: string;data: PatchApiV4CesiumAssetsIdPollDownloadingBody;params?: PatchApiV4CesiumAssetsIdPollDownloadingParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdPollDownloading(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdPollDownloadingMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollDownloading>>>
    export type PatchApiV4CesiumAssetsIdPollDownloadingMutationBody = PatchApiV4CesiumAssetsIdPollDownloadingBody
    export type PatchApiV4CesiumAssetsIdPollDownloadingMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdPollDownloading = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollDownloading>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollDownloadingBody;params?: PatchApiV4CesiumAssetsIdPollDownloadingParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollDownloading>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdPollDownloadingBody;params?: PatchApiV4CesiumAssetsIdPollDownloadingParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdPollDownloadingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/poll_processing_at_cesium operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdPollProcessingAtCesium = (
    id: string,
    patchApiV4CesiumAssetsIdPollProcessingAtCesiumBody?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBody,
    params?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdPollProcessingAtCesium200>(
      {url: `/api/v4/cesium_assets/${id}/poll_processing_at_cesium`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdPollProcessingAtCesiumBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdPollProcessingAtCesiumMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollProcessingAtCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollProcessingAtCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollProcessingAtCesium>>, {id: string;data: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdPollProcessingAtCesium(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollProcessingAtCesium>>>
    export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumMutationBody = PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBody
    export type PatchApiV4CesiumAssetsIdPollProcessingAtCesiumMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdPollProcessingAtCesium = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollProcessingAtCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollProcessingAtCesium>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumBody;params?: PatchApiV4CesiumAssetsIdPollProcessingAtCesiumParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdPollProcessingAtCesiumMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/poll_uploading operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdPollUploading = (
    id: string,
    patchApiV4CesiumAssetsIdPollUploadingBody?: PatchApiV4CesiumAssetsIdPollUploadingBody,
    params?: PatchApiV4CesiumAssetsIdPollUploadingParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdPollUploading200>(
      {url: `/api/v4/cesium_assets/${id}/poll_uploading`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdPollUploadingBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdPollUploadingMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollUploading>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollUploadingBody;params?: PatchApiV4CesiumAssetsIdPollUploadingParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollUploading>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollUploadingBody;params?: PatchApiV4CesiumAssetsIdPollUploadingParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollUploading>>, {id: string;data: PatchApiV4CesiumAssetsIdPollUploadingBody;params?: PatchApiV4CesiumAssetsIdPollUploadingParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdPollUploading(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdPollUploadingMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollUploading>>>
    export type PatchApiV4CesiumAssetsIdPollUploadingMutationBody = PatchApiV4CesiumAssetsIdPollUploadingBody
    export type PatchApiV4CesiumAssetsIdPollUploadingMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdPollUploading = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollUploading>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdPollUploadingBody;params?: PatchApiV4CesiumAssetsIdPollUploadingParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdPollUploading>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdPollUploadingBody;params?: PatchApiV4CesiumAssetsIdPollUploadingParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdPollUploadingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/reset_inital_files operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdResetInitalFiles = (
    id: string,
    patchApiV4CesiumAssetsIdResetInitalFilesBody?: PatchApiV4CesiumAssetsIdResetInitalFilesBody,
    params?: PatchApiV4CesiumAssetsIdResetInitalFilesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdResetInitalFiles200>(
      {url: `/api/v4/cesium_assets/${id}/reset_inital_files`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdResetInitalFilesBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdResetInitalFilesMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetInitalFiles>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdResetInitalFilesBody;params?: PatchApiV4CesiumAssetsIdResetInitalFilesParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetInitalFiles>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdResetInitalFilesBody;params?: PatchApiV4CesiumAssetsIdResetInitalFilesParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetInitalFiles>>, {id: string;data: PatchApiV4CesiumAssetsIdResetInitalFilesBody;params?: PatchApiV4CesiumAssetsIdResetInitalFilesParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdResetInitalFiles(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdResetInitalFilesMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetInitalFiles>>>
    export type PatchApiV4CesiumAssetsIdResetInitalFilesMutationBody = PatchApiV4CesiumAssetsIdResetInitalFilesBody
    export type PatchApiV4CesiumAssetsIdResetInitalFilesMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdResetInitalFiles = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetInitalFiles>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdResetInitalFilesBody;params?: PatchApiV4CesiumAssetsIdResetInitalFilesParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetInitalFiles>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdResetInitalFilesBody;params?: PatchApiV4CesiumAssetsIdResetInitalFilesParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdResetInitalFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/reset_to_initialized operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdResetToInitialized = (
    id: string,
    patchApiV4CesiumAssetsIdResetToInitializedBody?: PatchApiV4CesiumAssetsIdResetToInitializedBody,
    params?: PatchApiV4CesiumAssetsIdResetToInitializedParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdResetToInitialized200>(
      {url: `/api/v4/cesium_assets/${id}/reset_to_initialized`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdResetToInitializedBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdResetToInitializedMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetToInitialized>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdResetToInitializedBody;params?: PatchApiV4CesiumAssetsIdResetToInitializedParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetToInitialized>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdResetToInitializedBody;params?: PatchApiV4CesiumAssetsIdResetToInitializedParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetToInitialized>>, {id: string;data: PatchApiV4CesiumAssetsIdResetToInitializedBody;params?: PatchApiV4CesiumAssetsIdResetToInitializedParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdResetToInitialized(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdResetToInitializedMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetToInitialized>>>
    export type PatchApiV4CesiumAssetsIdResetToInitializedMutationBody = PatchApiV4CesiumAssetsIdResetToInitializedBody
    export type PatchApiV4CesiumAssetsIdResetToInitializedMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdResetToInitialized = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetToInitialized>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdResetToInitializedBody;params?: PatchApiV4CesiumAssetsIdResetToInitializedParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdResetToInitialized>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdResetToInitializedBody;params?: PatchApiV4CesiumAssetsIdResetToInitializedParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdResetToInitializedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Saves is_clipping for cesium asset
 */
export const patchApiV4CesiumAssetsIdSaveClipping = (
    id: string,
    patchApiV4CesiumAssetsIdSaveClippingBody: PatchApiV4CesiumAssetsIdSaveClippingBody,
    params?: PatchApiV4CesiumAssetsIdSaveClippingParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdSaveClipping200>(
      {url: `/api/v4/cesium_assets/${id}/save_clipping`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdSaveClippingBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdSaveClippingMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveClipping>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSaveClippingBody;params?: PatchApiV4CesiumAssetsIdSaveClippingParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveClipping>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSaveClippingBody;params?: PatchApiV4CesiumAssetsIdSaveClippingParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveClipping>>, {id: string;data: PatchApiV4CesiumAssetsIdSaveClippingBody;params?: PatchApiV4CesiumAssetsIdSaveClippingParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdSaveClipping(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdSaveClippingMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveClipping>>>
    export type PatchApiV4CesiumAssetsIdSaveClippingMutationBody = PatchApiV4CesiumAssetsIdSaveClippingBody
    export type PatchApiV4CesiumAssetsIdSaveClippingMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdSaveClipping = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveClipping>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSaveClippingBody;params?: PatchApiV4CesiumAssetsIdSaveClippingParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveClipping>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdSaveClippingBody;params?: PatchApiV4CesiumAssetsIdSaveClippingParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdSaveClippingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Saves default camera position for when Cesium Asset loads on a globe.
 */
export const patchApiV4CesiumAssetsIdSaveDefaultCamera = (
    id: string,
    patchApiV4CesiumAssetsIdSaveDefaultCameraBody: PatchApiV4CesiumAssetsIdSaveDefaultCameraBody,
    params?: PatchApiV4CesiumAssetsIdSaveDefaultCameraParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdSaveDefaultCamera200>(
      {url: `/api/v4/cesium_assets/${id}/save_default_camera`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdSaveDefaultCameraBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdSaveDefaultCameraMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveDefaultCamera>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSaveDefaultCameraBody;params?: PatchApiV4CesiumAssetsIdSaveDefaultCameraParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveDefaultCamera>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSaveDefaultCameraBody;params?: PatchApiV4CesiumAssetsIdSaveDefaultCameraParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveDefaultCamera>>, {id: string;data: PatchApiV4CesiumAssetsIdSaveDefaultCameraBody;params?: PatchApiV4CesiumAssetsIdSaveDefaultCameraParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdSaveDefaultCamera(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdSaveDefaultCameraMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveDefaultCamera>>>
    export type PatchApiV4CesiumAssetsIdSaveDefaultCameraMutationBody = PatchApiV4CesiumAssetsIdSaveDefaultCameraBody
    export type PatchApiV4CesiumAssetsIdSaveDefaultCameraMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdSaveDefaultCamera = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveDefaultCamera>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSaveDefaultCameraBody;params?: PatchApiV4CesiumAssetsIdSaveDefaultCameraParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSaveDefaultCamera>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdSaveDefaultCameraBody;params?: PatchApiV4CesiumAssetsIdSaveDefaultCameraParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdSaveDefaultCameraMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/set_utm operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdSetUtm = (
    id: string,
    patchApiV4CesiumAssetsIdSetUtmBody: PatchApiV4CesiumAssetsIdSetUtmBody,
    params?: PatchApiV4CesiumAssetsIdSetUtmParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdSetUtm200>(
      {url: `/api/v4/cesium_assets/${id}/set_utm`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdSetUtmBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdSetUtmMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSetUtm>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSetUtmBody;params?: PatchApiV4CesiumAssetsIdSetUtmParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSetUtm>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSetUtmBody;params?: PatchApiV4CesiumAssetsIdSetUtmParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSetUtm>>, {id: string;data: PatchApiV4CesiumAssetsIdSetUtmBody;params?: PatchApiV4CesiumAssetsIdSetUtmParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdSetUtm(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdSetUtmMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSetUtm>>>
    export type PatchApiV4CesiumAssetsIdSetUtmMutationBody = PatchApiV4CesiumAssetsIdSetUtmBody
    export type PatchApiV4CesiumAssetsIdSetUtmMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdSetUtm = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSetUtm>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdSetUtmBody;params?: PatchApiV4CesiumAssetsIdSetUtmParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdSetUtm>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdSetUtmBody;params?: PatchApiV4CesiumAssetsIdSetUtmParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdSetUtmMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /cesium_assets/:id/upload_to_cesium operation on cesium_asset resource
 */
export const patchApiV4CesiumAssetsIdUploadToCesium = (
    id: string,
    patchApiV4CesiumAssetsIdUploadToCesiumBody?: PatchApiV4CesiumAssetsIdUploadToCesiumBody,
    params?: PatchApiV4CesiumAssetsIdUploadToCesiumParams,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<PatchApiV4CesiumAssetsIdUploadToCesium200>(
      {url: `/api/v4/cesium_assets/${id}/upload_to_cesium`, method: 'PATCH',
      headers: {'Content-Type': 'application/vnd.api+json', },
      data: patchApiV4CesiumAssetsIdUploadToCesiumBody,
        params
    },
      options);
    }
  


export const getPatchApiV4CesiumAssetsIdUploadToCesiumMutationOptions = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdUploadToCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdUploadToCesiumBody;params?: PatchApiV4CesiumAssetsIdUploadToCesiumParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdUploadToCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdUploadToCesiumBody;params?: PatchApiV4CesiumAssetsIdUploadToCesiumParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdUploadToCesium>>, {id: string;data: PatchApiV4CesiumAssetsIdUploadToCesiumBody;params?: PatchApiV4CesiumAssetsIdUploadToCesiumParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  patchApiV4CesiumAssetsIdUploadToCesium(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchApiV4CesiumAssetsIdUploadToCesiumMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdUploadToCesium>>>
    export type PatchApiV4CesiumAssetsIdUploadToCesiumMutationBody = PatchApiV4CesiumAssetsIdUploadToCesiumBody
    export type PatchApiV4CesiumAssetsIdUploadToCesiumMutationError = ErrorsResponse

    export const usePatchApiV4CesiumAssetsIdUploadToCesium = <TError = ErrorsResponse,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdUploadToCesium>>, TError,{id: string;data: PatchApiV4CesiumAssetsIdUploadToCesiumBody;params?: PatchApiV4CesiumAssetsIdUploadToCesiumParams}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchApiV4CesiumAssetsIdUploadToCesium>>,
        TError,
        {id: string;data: PatchApiV4CesiumAssetsIdUploadToCesiumBody;params?: PatchApiV4CesiumAssetsIdUploadToCesiumParams},
        TContext
      > => {

      const mutationOptions = getPatchApiV4CesiumAssetsIdUploadToCesiumMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * /virtual_outcrop_models operation on virtual_outcrop_model resource
 */
export const getApiV4VirtualOutcropModels = (
    params?: GetApiV4VirtualOutcropModelsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetApiV4VirtualOutcropModels200>(
      {url: `/api/v4/virtual_outcrop_models`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiV4VirtualOutcropModelsQueryKey = (params?: GetApiV4VirtualOutcropModelsParams,) => {
    return [`/api/v4/virtual_outcrop_models`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV4VirtualOutcropModelsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV4VirtualOutcropModels>>, TError = ErrorsResponse>(params?: GetApiV4VirtualOutcropModelsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModels>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV4VirtualOutcropModelsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV4VirtualOutcropModels>>> = ({ signal }) => getApiV4VirtualOutcropModels(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModels>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV4VirtualOutcropModelsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV4VirtualOutcropModels>>>
export type GetApiV4VirtualOutcropModelsQueryError = ErrorsResponse

export const useGetApiV4VirtualOutcropModels = <TData = Awaited<ReturnType<typeof getApiV4VirtualOutcropModels>>, TError = ErrorsResponse>(
 params?: GetApiV4VirtualOutcropModelsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModels>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV4VirtualOutcropModelsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * /virtual_outcrop_models/:id operation on virtual_outcrop_model resource
 */
export const getApiV4VirtualOutcropModelsId = (
    id: string,
    params?: GetApiV4VirtualOutcropModelsIdParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetApiV4VirtualOutcropModelsId200>(
      {url: `/api/v4/virtual_outcrop_models/${id}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiV4VirtualOutcropModelsIdQueryKey = (id: string,
    params?: GetApiV4VirtualOutcropModelsIdParams,) => {
    return [`/api/v4/virtual_outcrop_models/${id}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV4VirtualOutcropModelsIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsId>>, TError = ErrorsResponse>(id: string,
    params?: GetApiV4VirtualOutcropModelsIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV4VirtualOutcropModelsIdQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsId>>> = ({ signal }) => getApiV4VirtualOutcropModelsId(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV4VirtualOutcropModelsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsId>>>
export type GetApiV4VirtualOutcropModelsIdQueryError = ErrorsResponse

export const useGetApiV4VirtualOutcropModelsId = <TData = Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsId>>, TError = ErrorsResponse>(
 id: string,
    params?: GetApiV4VirtualOutcropModelsIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV4VirtualOutcropModelsIdQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * /virtual_outcrop_models/:id/cesium_asset operation on cesium_asset resource
 */
export const getApiV4VirtualOutcropModelsIdCesiumAsset = (
    id: string,
    params?: GetApiV4VirtualOutcropModelsIdCesiumAssetParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<GetApiV4VirtualOutcropModelsIdCesiumAsset200>(
      {url: `/api/v4/virtual_outcrop_models/${id}/cesium_asset`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiV4VirtualOutcropModelsIdCesiumAssetQueryKey = (id: string,
    params?: GetApiV4VirtualOutcropModelsIdCesiumAssetParams,) => {
    return [`/api/v4/virtual_outcrop_models/${id}/cesium_asset`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiV4VirtualOutcropModelsIdCesiumAssetQueryOptions = <TData = Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsIdCesiumAsset>>, TError = ErrorsResponse>(id: string,
    params?: GetApiV4VirtualOutcropModelsIdCesiumAssetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsIdCesiumAsset>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV4VirtualOutcropModelsIdCesiumAssetQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsIdCesiumAsset>>> = ({ signal }) => getApiV4VirtualOutcropModelsIdCesiumAsset(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsIdCesiumAsset>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV4VirtualOutcropModelsIdCesiumAssetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsIdCesiumAsset>>>
export type GetApiV4VirtualOutcropModelsIdCesiumAssetQueryError = ErrorsResponse

export const useGetApiV4VirtualOutcropModelsIdCesiumAsset = <TData = Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsIdCesiumAsset>>, TError = ErrorsResponse>(
 id: string,
    params?: GetApiV4VirtualOutcropModelsIdCesiumAssetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV4VirtualOutcropModelsIdCesiumAsset>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV4VirtualOutcropModelsIdCesiumAssetQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




