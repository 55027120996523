import { faFlag, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gql from 'graphql-tag';
import { Badge } from 'react-daisyui';
import { useLoaderData } from 'react-router';
import { Link } from 'react-router-dom';
import { apolloClient } from '~/apollo/client';
import { miniModelParts } from '~/apollo/fragments';
import type { UploadMiniModelsPageQuery } from '~/apollo/generated/schema';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { Tooltip } from '~/components/common/Tooltip';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import {
  uploadOutcropUpdateMiniModelsRoute,
  uploadOutcropUpdateRoute,
} from '~/paths';
import { ucwords } from '~/utils/text';

const UPLOAD_MINI_MODELS_PAGE = gql`
  query UploadMiniModelsPage {
    miniModelList {
      ...miniModelParts
      outcrop {
        id
        name
      }
    }
  }

  ${miniModelParts}
`;

async function loader() {
  const res = await apolloClient.query<UploadMiniModelsPageQuery>({
    query: UPLOAD_MINI_MODELS_PAGE,
  });
  return { miniModels: res.data.miniModelList };
}

type MiniModel = UploadMiniModelsPageQuery['miniModelList'][number];

type ApprovalState = 'draft' | 'ready for approval' | 'approved';

function approvalState(mm: MiniModel): ApprovalState {
  if (mm.readyForApproval) return 'ready for approval';
  if (mm.approved) return 'approved';
  return 'draft';
}

type SortableMiniModel = MiniModel & {
  hasDescription: boolean;
  hasCoordinates: boolean;
  state: ApprovalState;
};

export default function UploadMiniModelsPage() {
  useBreadcrumb('routes/upload/mini-models', 'Manage Mini-Models');
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const sortable = loaderData.miniModels.map<SortableMiniModel>(mm => ({
    ...mm,
    hasDescription: !!mm.description?.length,
    hasCoordinates: !!(mm.latitude && mm.longitude),
    state: approvalState(mm),
  }));

  const { items: miniModels, sortIndicatorProps: siProps } = useSortFilter(
    sortable,
    'name',
    'name',
    'uploadMiniModels',
  );

  return (
    <>
      <PageHeading>Mini-Models</PageHeading>

      <Panel>
        <Panel.Heading>
          <Panel.Title>
            Mini-Model <Badge color="ghost">{miniModels.length}</Badge>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Body>
          <table className="table">
            <thead>
              <tr>
                <th>
                  <SortTrigger colName="id" sortIndicatorProps={siProps}>
                    ID
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="name" sortIndicatorProps={siProps}>
                    Name
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="outcrop.name"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Outcrop
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="yearCollected"
                    sortIndicatorProps={siProps}
                  >
                    Year
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="collectedBy"
                    sortIndicatorProps={siProps}
                  >
                    Collected By
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="hasDescription"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Desc
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="hasCoordinates"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Coords
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="state"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    State
                  </SortTrigger>
                </th>
              </tr>
            </thead>

            <tbody>
              {miniModels.map(mm => (
                <tr key={mm.id}>
                  <td>{mm.id}</td>
                  <td>
                    <Link
                      to={uploadOutcropUpdateMiniModelsRoute(mm.outcropId)}
                      className="link"
                    >
                      {mm.name}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={uploadOutcropUpdateRoute(mm.outcropId)}
                      className="link"
                    >
                      {mm.outcrop.name}
                    </Link>
                  </td>
                  <td>{mm.yearCollected}</td>
                  <td>{mm.collectedBy}</td>
                  <td className="text-center">
                    <EnabledIndicator value={mm.hasDescription} />
                  </td>
                  <td className="text-center">
                    <EnabledIndicator value={mm.hasCoordinates} />
                  </td>
                  <td className="text-center">
                    <Tooltip message={ucwords(approvalState(mm))}>
                      <ApprovalStateText state={approvalState(mm)} />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}

UploadMiniModelsPage.loader = loader;

function ApprovalStateText({ state }: { state: ApprovalState }) {
  if (state === 'ready for approval')
    return <FontAwesomeIcon icon={faFlag} className="text-warning" />;
  else if (state === 'approved') return <EnabledIndicator value={true} />;
  else return <FontAwesomeIcon icon={faPencil} className="text-muted" />;
}
