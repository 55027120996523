import type { AuthorityUserPartsFragment } from '~/apollo/generated/schema';
import { yup } from '~/utils/validation';

export function randomPassword(length = 12): string {
  var numberChars = '0123456789';
  var upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var lowerChars = 'abcdefghijklmnopqrstuvwxyz';
  var allChars = numberChars + upperChars + lowerChars;
  var randPasswordArray = Array(length);
  randPasswordArray[0] = numberChars;
  randPasswordArray[1] = upperChars;
  randPasswordArray[2] = lowerChars;
  randPasswordArray = randPasswordArray.fill(allChars, 3);

  function shuffleArray(array: string[]) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  return shuffleArray(
    randPasswordArray.map(x => x[Math.floor(Math.random() * x.length)]),
  ).join('');
}

export type UpdateProfileFormValues = {
  usernamePrefix?: string;
  usernameSuffix?: string;
  name: string;
  username: string;
  password?: string | null;
  passwordConfirm?: string | null;
};

export function initialValues(
  user: AuthorityUserPartsFragment,
): UpdateProfileFormValues {
  const [usernamePrefix, suffix] = user.username.split('@');

  return {
    usernamePrefix,
    usernameSuffix: `@${suffix}`,
    username: user.username,
    name: user.name,
    password: '',
    passwordConfirm: '',
  };
}

const userValidationSchemaBase = (
  companyMails: string[],
  currentUsername?: string | null,
) => ({
  usernamePrefix: yup
    .string()
    .label('username')
    .required()
    .test(
      'no-domain',
      `select your @domain.com from the next field`,
      value => !value?.match(/@/g),
    ),
  usernameSuffix: yup
    .string()
    .label('email domain')
    .required()
    .when(['username'], ([username]: any, schema) => {
      if (username === currentUsername) {
        return schema;
      }
      return schema.oneOf(
        companyMails,
        'must be a domain approved by your company',
      );
    }),
  username: yup.string().email().required(),
  name: yup.string().required(),
});

const passwordValidator = yup
  .string()
  .label('password')
  .test(
    'strong-password',
    'must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, and a number',
    value => {
      if (!value) return true;
      if (!value.match(/[a-z]+/g)) return false;
      if (!value.match(/[A-Z]+/g)) return false;
      if (!value.match(/\d+/g)) return false;
      return true;
    },
  );

const passwordConfirmValidator = yup
  .string()
  .when('password', ([password], schema) => {
    if (!password?.length) return schema.nullable().optional();
    return schema
      .required('must match password')
      .oneOf([password], 'must match password');
  });

export const validationSchema = (
  currentUsername: string,
  companyMails: string[],
) =>
  yup.object().shape({
    ...userValidationSchemaBase(companyMails, currentUsername),
    password: passwordValidator.optional().nullable(),
    passwordConfirm: passwordConfirmValidator,
  });

export type RegisterUserFormValues = {
  name: string;
  usernamePrefix: string;
  usernameSuffix: string;
  username: string;
  password: string;
  passwordConfirm: string;
};

export function initialValuesRegister(
  companyMails: string[],
): RegisterUserFormValues {
  return {
    name: '',
    usernamePrefix: '',
    usernameSuffix: companyMails.at(0) ?? '',
    password: '',
    passwordConfirm: '',
    username: '',
  };
}

export const validationSchemaRegister = (companyMails: string[]) =>
  yup.object().shape({
    ...userValidationSchemaBase(companyMails),
    passwordConfirm: passwordConfirmValidator,
  });

export type RegisterCompanyUserFormValues = {
  name: string;
  password: string;
  usernamePrefix: string;
  usernameSuffix: string;
  username: string;
};

export function initialValuesCompanyUser(
  companyMails: string[],
): RegisterCompanyUserFormValues {
  return {
    name: '',
    usernamePrefix: '',
    usernameSuffix: companyMails.at(0) ?? '',
    username: '',
    password: randomPassword(),
  };
}

export const validationSchemaCompanyUser = (companyMails: string[]) =>
  yup.object().shape({
    ...userValidationSchemaBase(companyMails),
    password: passwordValidator.required(),
  });
