import { gql, useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-daisyui';
import { toast } from 'react-toastify';
import { videoParts } from '~/apollo/fragments';
import type {
  UpdateVideoMutation,
  UpdateVideoMutationVariables,
  VideoPartsFragment,
} from '~/apollo/generated/schema';
import { FormErrors } from '~/components/common/FormErrors';
import { UrlBasedSOFormFields } from '~/components/supportingObject/urlBasedSO/UrlBasedSOFormFields';
import type { UrlBasedSOFormValues } from '~/utils/modules/urlBasedSO';
import {
  initialUrlBasedSO,
  urlBasedSOToInput,
  urlBasedSOValidationSchema,
} from '~/utils/modules/urlBasedSO';

const UPDATE_VIDEO = gql`
  mutation UpdateVideo($id: Int!, $video: UpdateUrlBasedSOInput!) {
    updateVideo(id: $id, video: $video) {
      ...videoParts
    }
  }

  ${videoParts}
`;

export function UpdateVideoForm({
  video,
  onUpdateSuccess,
}: {
  video: VideoPartsFragment;
  onUpdateSuccess?: () => void;
}) {
  const [updateVideo, { loading, error }] = useMutation<
    UpdateVideoMutation,
    UpdateVideoMutationVariables
  >(UPDATE_VIDEO);

  async function handleSubmit(values: UrlBasedSOFormValues) {
    try {
      await updateVideo({
        variables: {
          id: video.id,
          video: urlBasedSOToInput(values),
        },
      });
      toast.success('Video saved successfully.');
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error saving Video', err);
      toast.error('There was a problem saving the Video.');
    }
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialUrlBasedSO(video)}
      validationSchema={urlBasedSOValidationSchema}
    >
      <Form>
        <div className="space-y-4">
          <UrlBasedSOFormFields soType="Video" />
          <FormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
}
