import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

import { pageParts } from '~/apollo/fragments';
import { PAGES_BY_PAGE_NAME } from '~/apollo/operations/page';
import type {
  PagesByPageNameQuery,
  PagesByPageNameQueryVariables,
} from '~/apollo/generated/schema';
import { Role } from '~/apollo/generated/schema';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useAuth } from '~/contexts/auth';
import { adminPageRoute } from '~/paths';
import { Page } from './Page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const PAGE_LOADER = gql`
  query PageLoader($pageNames: [String!]!) {
    pageList(pageNames: $pageNames) {
      ...pageParts
    }
  }

  ${pageParts}
`;

type Props = {
  pageName: string;
  showTitle?: boolean;
};

export function PageLoader({ pageName, showTitle = true }: Props) {
  const { hasAnyRole } = useAuth();

  const { data, loading } = useQuery<
    PagesByPageNameQuery,
    PagesByPageNameQueryVariables
  >(PAGES_BY_PAGE_NAME, {
    variables: { pageNames: [pageName] },
  });

  const pageList = data?.pageList ?? [];
  const page = pageList.find(p => p.pageName === pageName);

  if (loading) return <SpinnerPlaceholder />;

  if (!page) {
    if (hasAnyRole([Role.RoleAdmin])) {
      return (
        <div className="w-full border-4 border-dashed border-error p-6 flex justify-center items-center text-center">
          <div className="space-y-2">
            <div>
              Page <strong>{pageName}</strong> is missing.
            </div>

            <Link
              to={{
                pathname: adminPageRoute(),
                search: `slug=${pageName}`,
              }}
              className="btn btn-primary btn-sm"
            >
              <FontAwesomeIcon icon={faPlus} /> Create Page
            </Link>
          </div>
        </div>
      );
    }

    return null;
  }

  return <Page page={page} showTitle={showTitle} />;
}
