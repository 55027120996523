import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Entity, ScreenSpaceEventHandler, Viewer } from 'cesium';
import type { LatLngHeightHPR } from '~/components/cesium/CesiumViewer';
import { CesiumViewer } from '~/components/cesium/CesiumViewer';
import {
  addInfoBoxPoint,
  addPoint,
  enableEntityInfoBox,
  TerrainProvider,
} from '~/components/cesium/cesiumUtils';
import type { SubregionVftViewerPageQuery } from '~/apollo/generated/schema';
import { cn } from '~/utils/common';
import { Button } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoCamera } from '@fortawesome/free-solid-svg-icons';
import { Heading } from '~/components/common/Heading';
import { useVftViewerPageContext } from '~/routes/vft-viewer';
import type { SO } from '~/routes/upload/outcrop-subregions/$srId/placements';
import {
  initialTilesets,
  isIframeType,
} from '~/routes/upload/outcrop-subregions/$srId/placements';

type Subregion = SubregionVftViewerPageQuery['outcropSubregionList'][number];

type vftCameraLocation = LatLngHeightHPR & { name: string };

type SidebarProps = {
  vftCameraLocations: vftCameraLocation[];
};

function Sidebar(props: SidebarProps) {
  return (
    <div className="space-y-4">
      <Heading level={3}>Camera Points</Heading>
      {props.vftCameraLocations.map((cameraLocation, index) => {
        return (
          <VftCameraItem
            cameraLocation={cameraLocation}
            key={`${cameraLocation.name}-${Date.now()}`}
            index={index}
          />
        );
      })}
    </div>
  );
}

function VftCameraItem({
  cameraLocation,
  index,
}: {
  cameraLocation: vftCameraLocation;
  index: number;
}) {
  const { vftState, flyToLocation } = useVftViewerPageContext();

  function handleFlyTo() {
    flyToLocation(cameraLocation, index);
  }

  return (
    <div className="flex justify-between gap-6 w-full">
      <div
        className={cn(
          'self-center ml-2 pl-2 border-l border-slate-200 border-dotted text-base max-w-full leading-4',
          {
            'font-bold': index === vftState,
          },
        )}
      >
        {cameraLocation.name}
      </div>

      <div className="text-right">
        {cameraLocation && (
          <Button
            type="button"
            color="ghost"
            size="xs"
            onClick={() => {
              handleFlyTo();
            }}
          >
            <FontAwesomeIcon icon={faVideoCamera} />
          </Button>
        )}
      </div>
    </div>
  );
}

const addEntity =
  (viewer: Viewer) =>
  (item: SO): Entity | null => {
    if (item.placement && item.placement.location) {
      const position: LatLngHeightHPR = {
        latitude: item.placement.location.latitude,
        longitude: item.placement.location.longitude,
        height: item.placement.location.height,
        heading: item.placement.location.heading || 0,
        pitch: item.placement.location.pitch || 0,
        roll: item.placement.location.roll || 0,
      };
      let picUrl = item.file?.signedUrl;
      if (!picUrl && item.pictures) {
        picUrl = item.pictures.at(0)?.file.signedUrl;
      }
      const itemId = `${item.__typename}-${item.id}`;
      if (viewer.entities.getById(itemId) === undefined && item.placement) {
        if (item.__typename && isIframeType(item) && item.url) {
          return addInfoBoxPoint(
            viewer,
            itemId,
            item.url,
            position,
            item.name,
            true,
            item.placement.panelWidth ?? undefined,
            item.placement.panelHeight ?? undefined,
          );
        } else if (picUrl) {
          return addInfoBoxPoint(
            viewer,
            itemId,
            picUrl,
            position,
            item.name,
            false,
            item.placement.panelWidth ?? undefined,
            item.placement.panelHeight ?? undefined,
          );
        } else {
          return addPoint(viewer, itemId, position, item.name);
        }
      }
    }
    return null;
  };

const createAllEntities = (viewer: Viewer, subregion: Subregion) => {
  subregion.outcrops.forEach(oc => {
    if (viewer) {
      const addToViewer = addEntity(viewer);
      oc.crossSections.forEach(addToViewer);
      oc.pictures.forEach(addToViewer);
      oc.facies.forEach(addToViewer);
      oc.sedimentaryLogs.forEach(addToViewer);
      oc.wellLogs.forEach(addToViewer);
      oc.production.forEach(addToViewer);
      oc.reservoirModels.forEach(addToViewer);
      oc.trainingImages.forEach(addToViewer);
      oc.variograms.forEach(addToViewer);
      oc.gigaPans.forEach(addToViewer);
      oc.miniModels.forEach(addToViewer);
      oc.photo360s.forEach(addToViewer);
      oc.videos.forEach(addToViewer);
      oc.studies.forEach(study => {
        study.crossSections.forEach(addToViewer);
        study.pictures.forEach(addToViewer);
        study.facies.forEach(addToViewer);
        study.sedimentaryLogs.forEach(addToViewer);
        study.wellLogs.forEach(addToViewer);
        study.production.forEach(addToViewer);
        study.reservoirModels.forEach(addToViewer);
        study.trainingImages.forEach(addToViewer);
        study.variograms.forEach(addToViewer);
        study.gigaPans.forEach(addToViewer);
      });
    }
  });
};

export function PageInner({ subregion }: { subregion: Subregion }) {
  const { viewer, setViewer, vftState, flyToLocation } =
    useVftViewerPageContext();
  const [infoBoxHandler, setInfoBoxHandler] =
    useState<ScreenSpaceEventHandler>();

  useEffect(() => {
    if (viewer && subregion) {
      createAllEntities(viewer, subregion);
    }
  }, [viewer, subregion]);

  const availableTilesets = useMemo(() => {
    if (subregion) {
      return initialTilesets(subregion);
    }
    return [];
  }, [subregion]);

  // const cameraLocations: vftCameraLocation[] = [
  //   {
  //     name: 'point1',
  //     latitude: 39.688197583382475,
  //     longitude: -110.85246050868747,
  //     height: 2855.7570806983235,
  //     heading: 6.283185307179583,
  //     pitch: -0.5001772363402912,
  //     roll: 6.28318530717957,
  //   },
  //   {
  //     name: 'point2',
  //     latitude: 39.688197583382475,
  //     longitude: -110.85246050868747,
  //     height: 2855.7570806983235,
  //     heading: 6.283185307179583,
  //     pitch: -0.4001772363402912,
  //     roll: 6.28318530717957,
  //   },
  //   {
  //     name: 'point3',
  //     latitude: 39.688197583382475,
  //     longitude: -110.85246050868747,
  //     height: 2855.7570806983235,
  //     heading: 6.283185307179583,
  //     pitch: -0.6001772363402912,
  //     roll: 6.28318530717957,
  //   },
  // ];

  const cameraLocations: vftCameraLocation[] = [];

  function goToCameraLocationIndex(i: number) {
    const location = cameraLocations[i];
    flyToLocation(location, i);
  }

  function infoboxClickHandler(viewer: Viewer) {
    if (infoBoxHandler) {
      infoBoxHandler.destroy();
    }
    if (viewer) {
      const handler = enableEntityInfoBox(viewer, entity => {
        console.log('entity', entity);
      });
      setInfoBoxHandler(handler);
    }
  }

  return (
    <>
      <div className="grid lg:grid-cols-4 gap-6">
        {cameraLocations.length > 0 && (
          <div className="max-h-screen overflow-auto">
            <Sidebar vftCameraLocations={cameraLocations} />
          </div>
        )}
        <div className="lg:col-span-6">
          <CesiumViewer
            sendCesiumViewer={setViewer}
            initialTilesets={availableTilesets}
            showGlobe
            terrainProvider={TerrainProvider.World}
            infoBox={true}
            afterLoad={useCallback(infoboxClickHandler, [infoBoxHandler])}
          />
          {cameraLocations.length > 1 && (
            <div className="flex flex-col py-2 items-left justify-center">
              <p>Camera Position</p>
              <div className="flex items-center gap-2 py-2">
                <Button
                  type="button"
                  onClick={() => {
                    goToCameraLocationIndex(vftState - 1);
                  }}
                  disabled={vftState <= 0}
                  color="primary"
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    goToCameraLocationIndex(vftState + 1);
                  }}
                  disabled={vftState === cameraLocations.length}
                  color="primary"
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
