import type { Cesium3DTileset, Viewer } from 'cesium';
import {
  Cartesian3,
  Cartographic,
  Math as CesiumMath,
  Ellipsoid,
  HeadingPitchRoll,
} from 'cesium';
import { Form, Formik, useFormikContext } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Button, Collapse, Form as DaisyForm, Toggle } from 'react-daisyui';
import { toast } from 'react-toastify';
import { z } from 'zod';
import type { UpdateVomRouteQuery } from '~/apollo/generated/schema';
import type { LatLngHeightHPR } from '~/components/cesium/CesiumViewer';
import { CesiumViewer } from '~/components/cesium/CesiumViewer';
import type {
  CameraParams,
  TilesetParams,
} from '~/components/cesium/cesiumUtils';
import {
  getCamera,
  getCurrentZoomDistance,
  resetCameraOrientation,
  setDepthTest,
  TerrainProvider,
  updateTilesetModelMatrixWithPositionHpr,
} from '~/components/cesium/cesiumUtils';
import VomPlacementFields from '~/components/cesium/placementFields';
import type { UtmFormValues } from '~/components/cesium/utmFields';
import VomUtmFields from '~/components/cesium/utmFields';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { BodyContext } from '~/components/layout/BodyContext';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';
import {
  attributesSchema,
  locationSchema,
  placementFormSchema,
  utmFormSchema,
} from '~/utils/modules/placement';

import { queryClient } from '~/main';
import type {
  GetApiV4CesiumAssetsIdQueryResult,
  PatchApiV4CesiumAssetsIdPlaceBody,
  PatchApiV4CesiumAssetsIdSaveDefaultCameraBody,
  PatchApiV4CesiumAssetsIdSetUtmBody,
} from '~/openapi/api-v4';
import {
  CesiumAssetAttributesState,
  getGetApiV4VirtualOutcropModelsIdQueryKey,
  patchApiV4CesiumAssetsIdMarkAsCompleted,
  patchApiV4CesiumAssetsIdPlace,
  patchApiV4CesiumAssetsIdSaveClipping,
  patchApiV4CesiumAssetsIdSaveDefaultCamera,
  patchApiV4CesiumAssetsIdSetUtm,
  useGetApiV4CesiumAssetsId,
} from '~/openapi/api-v4';

type CesiumAsset =
  UpdateVomRouteQuery['virtualOutcropModelList'][number]['cesiumAsset'];

type PlacementProps = {
  cesiumAsset: NonNullable<CesiumAsset>;
  cesiumAssetId: string;
  vomId: string;
  state: CesiumAssetAttributesState;
};

const defaultPlacement: LatLngHeightHPR = {
  latitude: 0,
  longitude: 0,
  height: 0,
  heading: 0,
  pitch: 0,
  roll: 0,
};

const defaultUtmPlacement: UtmFormValues = {
  northing: 0,
  easting: 0,
  zone: 0,
  hemisphere: 'north',
};

const fullScreenClass = 'max-w-full';
const disableFormClassNames = 'pointer-events-none opacity-25';

type CesiumAssetV4 = z.infer<typeof attributesSchema>;
type CALocation = z.infer<typeof locationSchema>;

export function PlaceableCompleteState({
  vomId,
  cesiumAssetId,
  state,
}: {
  vomId: string;
  cesiumAssetId: string;
  state: CesiumAssetAttributesState;
}) {
  const ctx = useUpdateVomOutletContext();
  const cesiumAsset = ctx.vom.cesiumAsset;

  return cesiumAsset ? (
    <PlacementPage
      cesiumAsset={cesiumAsset}
      cesiumAssetId={cesiumAssetId}
      vomId={vomId}
      state={state}
    />
  ) : (
    <SpinnerPlaceholder />
  );
}

function PlacementPage({
  cesiumAsset,
  cesiumAssetId,
  vomId,
  state,
}: PlacementProps) {
  const [cesiumTileset, setCesiumTileset] = useState<Cesium3DTileset | null>(
    null,
  );
  const [initialized, setInitialized] = useState<Boolean>(false);
  const [cesiumAssetV4, setCesiumAssetv4] = useState<CesiumAssetV4 | null>(null);
  const [cesiumViewer, setCesiumViewer] = useState<Viewer | null>(null);
  const [placement, setPlacement] = useState<LatLngHeightHPR>(defaultPlacement);
  const [utmPlacement, setUtmPlacement] =
    useState<UtmFormValues>(defaultUtmPlacement);
  const [placementFormClass, setPlacementFormClass] = useState<string>(
    disableFormClassNames,
  );
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [cameraLocation, setCameraLocation] = useState<CALocation>();
  const [tilesetClipping, setTilesetClipping] = useState<boolean>(false);
  const { toggleClassName } = useContext(BodyContext);
  const [cameraZoomLevel, setCameraZoomLevel] = useState<number>(1);

  const updateData = (data: GetApiV4CesiumAssetsIdQueryResult) => {
    console.log('updatedata', data)
    const parsed = attributesSchema.safeParse(data?.data?.attributes);
    const parsedLocation = locationSchema.safeParse(data?.included?.at(0)?.attributes)
    const parsedCamera = locationSchema.safeParse(data?.included?.at(1)?.attributes)
    if (parsed.success) {
      setCesiumAssetv4(parsed.data);
      if (parsed.data.utm_data) {
        setUtmPlacement({
          northing: parsed.data.utm_data.utm_northings, 
          easting: parsed.data.utm_data.utm_eastings,
          zone: parsed.data.utm_data.utm_zone,
          hemisphere: parsed.data.utm_data.utm_hemisphere,
        });
      } else {
        setCollapsed(false);
      }
      if (parsedLocation.success) {
        setPlacement({
          latitude: parsedLocation.data.latitude,
          longitude: parsedLocation.data.longitude,
          height: parsedLocation.data.height,
          heading: parsedLocation.data.heading,
          pitch: parsedLocation.data.pitch,
          roll: parsedLocation.data.roll,
        });
        if (
          parsedLocation.data.latitude !== 0 &&
          parsedLocation.data.longitude !== 0
        ) {
          setPlacementFormClass('');
        }
      } else {
        console.log('parsed location fail')
      }
      if (parsedCamera.success) {
        setCameraLocation(parsedCamera.data);
      } else {
        console.log('parsed cam fail')
      }
      if (parsed.data.is_clipping) {
        setTilesetClipping(parsed.data.is_clipping);
        setDepthTest(cesiumViewer, parsed.data.is_clipping);
      }
    } else {
      console.log('parse issue', parsed);
    }
  };

  useGetApiV4CesiumAssetsId(
    cesiumAssetId,
    {
      include: 'location,default_camera',
    },
    {
      query: {
        onSuccess: updateData,
        enabled: !!cesiumAsset,
      },
    },
  );

  if (!cesiumAsset) return <NotFound />;
  if (!cesiumAsset.localPath) return <NotFound />;
  if (!cesiumAsset.assetToken) return <NotFound />;

  const ca: TilesetParams = {
    assetToken: cesiumAsset.assetToken,
    localPath: cesiumAsset.localPath,
    transform: {
      location: Cartographic.fromDegrees(
        placement.longitude,
        placement.latitude,
        placement.height,
      ),
      orientation: new HeadingPitchRoll(
        placement.heading,
        placement.pitch,
        placement.roll,
      ),
    },
  };

  if (cameraLocation) {
    const dc = cameraLocation;
    ca.defaultCamera = {
      position: Cartesian3.fromDegrees(dc.longitude, dc.latitude, dc.height),
      orientation: new HeadingPitchRoll(dc.heading, dc.pitch, dc.roll),
    };
    if (cesiumViewer && !initialized) {
      cesiumViewer.scene.camera.flyTo({
        destination: ca.defaultCamera.position,
        orientation: ca.defaultCamera.orientation,
      });
      setInitialized(true);
    }
  }

  async function handleSubmit(values: LatLngHeightHPR) {
    if (!cesiumAsset) return;
    const body: PatchApiV4CesiumAssetsIdPlaceBody = {
      data: {
        attributes: {
          location: {
            latitude: values.latitude,
            longitude: values.longitude,
            height: values.height,
            heading: values.heading,
            pitch: values.pitch,
            roll: values.roll,
          },
        },
        id: cesiumAssetId,
      },
    };
    await patchApiV4CesiumAssetsIdPlace(cesiumAssetId, body).catch(e => {
      toast.error('Error saving position.');
      return;
    });
    toast.success('Position Saved');
  }

  async function handleUtmSubmit(values: UtmFormValues) {
    if (!cesiumAsset) return;

    const hemisphere = z
      .enum(['north', 'south'])
      .optional()
      .parse(values.hemisphere);

    const body: PatchApiV4CesiumAssetsIdSetUtmBody = {
      data: {
        attributes: {
          utm_data: {
            utm_northings: values.northing,
            utm_eastings: values.easting,
            utm_zone: values.zone,
            utm_hemisphere: hemisphere,
          },
        },
        id: cesiumAssetId,
        type: 'cesium_asset',
      },
    };

    const resp = await patchApiV4CesiumAssetsIdSetUtm(cesiumAssetId, body, {
      include: 'location',
    }).catch(e => {
      toast.error('Error saving UTM position.');
      return;
    });
    const parsed = locationSchema.safeParse(resp?.included?.at(0)?.attributes);
    if (parsed.success) {
      setPlacement({
        latitude: parsed.data.latitude,
        longitude: parsed.data.longitude,
        height: parsed.data.height,
        heading: parsed.data.heading,
        pitch: parsed.data.pitch, 
        roll: parsed.data.roll,
      });
      setPlacementFormClass('');
      setCollapsed(true);
      toast.success('UTM Position Saved.');
    } else {
      toast.error('Error saving UTM as position');
    }
  }

  const saveCameraPosition = async (viewer: Viewer | null) => {
    if (!viewer) {
      return;
    }
    const cameraLocation = getCamera(viewer);
    if (!cameraLocation) {
      toast.error('Error getting camera location');
      return;
    }
    await sendCameraPosition(cameraLocation);
  };

  const sendCameraPosition = async (cameraLocation: CameraParams) => {
    const cart = Ellipsoid.WGS84.cartesianToCartographic(
      cameraLocation.position,
    );
    const body: PatchApiV4CesiumAssetsIdSaveDefaultCameraBody = {
      data: {
        attributes: {
          default_camera: {
            longitude: CesiumMath.toDegrees(cart.longitude),
            latitude: CesiumMath.toDegrees(cart.latitude),
            height: cart.height,
            heading: cameraLocation.orientation.heading,
            pitch: cameraLocation.orientation.pitch,
            roll: cameraLocation.orientation.roll,
          },
        },
        id: cesiumAssetId,
      },
    };

    const resp = await patchApiV4CesiumAssetsIdSaveDefaultCamera(
      cesiumAssetId,
      body,
    );

    if (resp.data) {
      // setCameraLocation();
      toast.success('Camera Position Saved');
    } else {
      toast.error('Error saving camera position');
    }
  };

  const FormObserver: React.FC = () => {
    // need to observe form changes so we can update the cesium model matrix
    const { values } = useFormikContext<LatLngHeightHPR>();
    const valuesValid = placementFormSchema.isValidSync(values);
    useEffect(() => {
      if (valuesValid && cesiumTileset && cesiumTileset.modelMatrix) {
        const formValues = placementFormSchema.validateSync(values);
        updateTilesetModelMatrixWithPositionHpr(cesiumTileset, formValues);
      }
    }, [
      values,
      values.latitude,
      values.longitude,
      values.height,
      values.heading,
      values.pitch,
      values.roll,
      valuesValid,
    ]);
    return null;
  };

  const markAsComplete = async () => {
    await patchApiV4CesiumAssetsIdMarkAsCompleted(cesiumAssetId, {
      data: {
        id: cesiumAssetId,
      },
    })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: getGetApiV4VirtualOutcropModelsIdQueryKey(vomId),
        });
        toast.success('Mark as complete success.');
      })
      .catch(e => {
        toast.error('Mark as complete failed.');
        return;
      });
  };

  const cameraCallback = (viewer: Viewer) => {
    const dist = getCurrentZoomDistance(viewer);
    if (!dist) {
      setCameraZoomLevel(1);
    } else {
      if (dist < 250) {
        setCameraZoomLevel(0.1);
      } else if (dist < 500) {
        setCameraZoomLevel(0.25);
      } else if (dist < 1000) {
        setCameraZoomLevel(0.5);
      } else if (dist < 2000) {
        setCameraZoomLevel(1);
      }
    }
  };

  const saveTilesetClipping = (clipping: boolean) => {
    patchApiV4CesiumAssetsIdSaveClipping(cesiumAssetId, {
      data: {
        id: cesiumAssetId,
        attributes: {
          is_clipping: clipping,
        },
      },
    })
      .then(() => {
        toast.success('Clipping state saved');
        setDepthTest(cesiumViewer, clipping);
        setTilesetClipping(clipping);
      })
      .catch(e => {
        toast.error('Clipping state saving failed');
        return;
      });
  };

  return (
    <div className="space-y-6">
      <CesiumViewer
        initialTilesets={[ca]}
        terrainProvider={TerrainProvider.World}
        sendTileset={setCesiumTileset}
        updatePlacement={setPlacement}
        sendCesiumViewer={setCesiumViewer}
        cameraCallback={cameraCallback}
        enableClickPlacement={true}
      />
      <div className="grid grid-cols-6 items-center gap-2">
        <div className="col-start-1 col-end-2">
          <Button
            onClick={() => {
              toggleClassName(fullScreenClass);
            }}
            color="primary"
            size="md"
            className="gap-1"
          >
            Toggle Immersive Mode
          </Button>
        </div>
        <div className="col-start-2 col-end-3">
          <DaisyForm.Label title="Tileset Clipping">
            <Toggle
              className="m-2"
              color="primary"
              checked={tilesetClipping}
              onChange={e => {
                saveTilesetClipping(e.target.checked);
              }}
            />
          </DaisyForm.Label>
        </div>
        <div className="col-end-6">
          <Button
            onClick={() => {
              resetCameraOrientation(cesiumViewer);
            }}
            color="primary"
            size="md"
            className="gap-1"
          >
            Camera Reset
          </Button>
        </div>
        <div className="col-end-7">
          <Button
            onClick={() => {
              saveCameraPosition(cesiumViewer);
            }}
            color="primary"
            size="md"
            className="gap-1 "
          >
            {cameraLocation ? 'Update Camera Location' : 'Save Camera Location'}
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 text-center py-5">
        <Formik
          initialValues={utmPlacement}
          onSubmit={handleUtmSubmit}
          validationSchema={utmFormSchema}
        >
          <Form>
            <Collapse
              icon={'arrow'}
              checkbox={true}
              open={!collapsed}
              onToggle={() => {
                setCollapsed(!collapsed);
              }}
              className="shadow-md border border-default pb-2 rounded-none"
            >
              <Collapse.Title className="px-3 py-2 bg-slate-100 text-base-content">
                <div className="text">UTM Placement</div>
              </Collapse.Title>
              <Collapse.Content className="collapse-content">
                <VomUtmFields utmPlacement={utmPlacement} />
                <p className="text-red-500 m-2">
                  Saving will override current changes.
                </p>
                <Button
                  type="submit"
                  color="neutral"
                  size="md"
                  className="gap-1"
                >
                  Apply
                </Button>
                <Button
                  onClick={() => {
                    setPlacementFormClass('');
                  }}
                  color="ghost"
                  size="md"
                  type="button"
                  className="gap-2"
                >
                  Skip Placement
                </Button>
              </Collapse.Content>
            </Collapse>
          </Form>
        </Formik>
        <Formik
          initialValues={placement}
          onSubmit={handleSubmit}
          validationSchema={placementFormSchema}
        >
          <Form>
            <FormObserver />
            <div
              className={
                'shadow-md border border-default pb-2 ' + placementFormClass
              }
            >
              <div className="px-3 py-2 bg-slate-100 text-base-content">
                <div className="text">Placement</div>
              </div>
              <VomPlacementFields
                placement={placement}
                zoomValue={cameraZoomLevel}
              />
              {cameraLocation ? null : (
                <p className="text-red-500 m-2">
                  Camera position must be saved before saving placement or
                  marking as complete
                </p>
              )}
              <Button
                type="submit"
                color="ghost"
                size="md"
                className="gap-1"
                disabled={!cameraLocation}
              >
                Save Placement
              </Button>
              <Button
                type="button"
                color="neutral"
                size="md"
                onClick={markAsComplete}
                className="gap-1"
                disabled={
                  !cameraLocation || state === CesiumAssetAttributesState.complete
                }
              >
                {state === CesiumAssetAttributesState.complete
                  ? 'Completed'
                  : 'Mark As Complete'}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
